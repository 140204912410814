import React, { useEffect, useState } from "react";
import {
  BsFolder2,
  BsChevronRight,
  BsTrash,
  BsFillPersonPlusFill,
  BsChatSquareDots,
  BsTextCenter,
  BsCircle,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const membersList = [{ id: 1 }, { id: 2 }, { id: 3 }];

function ViewGroup() {
  const navigate = useNavigate();
  const [selectedDrink, setSelectedDrink] = useState<String>();

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDrink(event.target.value);
  };

  const viewOtherProfile = (membersList: any) => {
    console.log(membersList);
    navigate("/otherProfile", { state: membersList });
  };

  return (
    <div className="px-6 pt-3 sm:px-14">
      <div className="text-xl py-2">Creation Date</div>
      <div className="text-3xl pb-2 font-bold">MBA 2023</div>
      <div className="text-xl pb-2">
        Select people to message or add to group
      </div>
      <div>
        <button className="flex text-blue-500 pl-2 w-full">
          <BsFillPersonPlusFill className="text-xl mr-2 my-1" />
          <div className="py-1 border-gray-200 border-b w-full text-left">
            <span>Invite to Group</span>
          </div>
        </button>
        <button className="flex text-blue-500 pl-2 w-full">
          <BsChatSquareDots className="text-xl mr-2 my-1" />
          <div className="py-1 border-gray-200 border-b w-full text-left">
            <span>Message Selected Contracts</span>
          </div>
        </button>
        <button
          className="flex text-blue-500 pl-2 w-full"
          onClick={() => {
            viewOtherProfile(membersList);
          }}
        >
          <BsTextCenter className="text-xl mr-2 my-1" />
          <div className="py-1 border-gray-200 border-b w-full text-left">
            <span>Enter Individual Profile Connector</span>
          </div>
        </button>
      </div>
      <div className="py-3">
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
        <div className="flex py-1">
          <BsCircle className="mt-0.5 mr-2" />
          <span>Person</span>
        </div>
      </div>
    </div>
  );
}

export default ViewGroup;
