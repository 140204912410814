import {
  createStore,
  combineReducers,
  applyMiddleware,
} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { AuthReducer } from './reducers/AuthReducer';
import { ProfileReducer } from './reducers/profileReducer';
import { ChatReducer } from './reducers/ChatReducer';
import { FriendReducer } from './reducers/FriendReducer';

const reducers = combineReducers({
  userAuth: AuthReducer,
  userData: ProfileReducer,
  chatData: ChatReducer,
  friendData: FriendReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo')!)
  : undefined;

const initialState = {
  userAuth: { userInfo: userInfoFromStorage },
} as {};
const middleware = [thunk];

const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
export type RootState = ReturnType<typeof store.getState>;
