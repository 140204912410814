import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../store";
import { ProfileState } from "../../../reducers/profileReducer";
import { IoPersonAddOutline } from "react-icons/io5";

const tab = [
  { id: 1, name: "Members" },
  { id: 2, name: "SubGroup" },
  { id: 3, name: "Media" },
  { id: 4, name: "Files" },
  { id: 5, name: "Link" },
  { id: 6, name: "GIF" },
];

const user = [
  {
    id: "usr_06fcc6632e",
    first_name: "Jeff",
    last_name: "Schowalter",
    email: "doyle.maxine@example.com",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+ex",
    description: "Aliquam eos rerum ipsa qui molestiae esse.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
    member: true,
  },
  {
    id: "usr_e551f9f1c9",
    first_name: "Arianna",
    last_name: "Langosh",
    email: "goldner.emmanuel@example.net",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+hic",
    description: "Natus quia laudantium odit qui facere qui.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
    member: false,
  },
];

const Tabs = (data: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [openTab, setOpenTab] = React.useState(0);

  const TabElement = tab.map((m) => {
    return (
      <li
        className="-mb-px sm:mr-2 last:mr-0 flex-auto text-center"
        key={m.name}
      >
        <a
          className={
            "text-xs font-bold px-2 py-2 rounded block leading-normal " +
            (openTab === m.id && data.openMessage !== 1
              ? "text-blue-500 border-b-4 border-blue-500"
              : "text-gray-400")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(m.id);
          }}
          data-toggle="tab"
          href={`#${m.name}`}
          role="tablist"
        >
          {m.name}
        </a>
      </li>
    );
  });

  const MembersList = user.map((m) => {
    return (
      <div className="flex" key={m.id}>
        <div className="py-2">
          <img
            src={m.profile_image}
            className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
          />
        </div>
        <div className="py-2 pl-2 border-b border-gray-200 w-full flex justify-between">
          <div className="grid">
            <span className="text-sm sm:text-base">
              {m.first_name} {m.last_name}{" "}
            </span>
            <span className="text-xs text-gray-400">last seen just now</span>
          </div>
        </div>
      </div>
    );
  });

  const SubGroup = user.map((m) => {
    return (
      <div className="flex" key={m.id}>
        <div className="py-2">
          <img
            src={m.profile_image}
            className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
          />
        </div>
        <div className="py-2 pl-2 border-b border-gray-200 w-full flex justify-between">
          <div className="grid">
            <span className="text-sm sm:text-base">
              {m.first_name} {m.last_name}{" "}
            </span>
            <span className="text-xs text-gray-400">40 members</span>
            <span className="text-xs text-gray-400">Expires March 7th</span>
          </div>
          {m.member == true ? (
            <div className="grid">
              <span className="text-xs">You are a member</span>
              <button
                className="py-1 px-2 mr-2 mb-2 text-xs font-medium text-gray-900 focus:outline-none bg-gray-300 rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                type="button"
              >
                LEAVE GROUP
              </button>
            </div>
          ) : (
            <div className="my-auto">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs mr-2 px-8 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              >
                JOIN
              </button>
            </div>
          )}
        </div>
      </div>
    );
  });

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full">
          <ul
            className="flex mb-0 list-none flex-wrap pt-3 pb-4 px-1 sm:px-3 flex-row"
            role="tablist"
          >
            {TabElement}
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-16 rounded">
            <div className="px-2 sm:px-8 py-2 sm:py-5 flex-auto">
              <div className="tab-content tab-space">
                <div
                  className={openTab === 1 ? "block" : "hidden"}
                  id="Members"
                >
                  <div>
                    <button className="flex text-blue-500 pl-2 w-full">
                      <IoPersonAddOutline className="text-xl mr-4 my-3" />
                      <div className="py-3 border-gray-200 border-b w-full text-left">
                        <span>Add Members</span>
                      </div>
                    </button>
                  </div>
                  {MembersList}
                </div>
                <div
                  className={openTab === 2 ? "block" : "hidden"}
                  id="SubGroup"
                >
                  {SubGroup}
                </div>
                <div
                  className={openTab === 3 ? "block" : "hidden"}
                  id="Clubs"
                ></div>
                <div
                  className={openTab === 4 ? "block" : "hidden"}
                  id="Experience"
                ></div>
                <div
                  className={openTab === 5 ? "block" : "hidden"}
                  id="Direction"
                ></div>
                <div
                  className={openTab === 6 ? "block" : "hidden"}
                  id="Interests"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender(data: any) {
  return (
    <>
      <Tabs />
    </>
  );
}
