import { useEffect, useState } from "react";
import ForwardChatComponent from "./components/forward-chat-component";
import ForwardCalendarComponent from "./components/forward-calendar-component";

const user = [
  {
    id: "usr_06fcc6632e",
    first_name: "Jeff",
    last_name: "Schowalter",
    email: "doyle.maxine@example.com",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+ex",
    description: "Aliquam eos rerum ipsa qui molestiae esse.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
  },
  {
    id: "usr_e551f9f1c9",
    first_name: "Arianna",
    last_name: "Langosh",
    email: "goldner.emmanuel@example.net",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+hic",
    description: "Natus quia laudantium odit qui facere qui.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
  },
];

const ForwardComponent = ({ setOpenDrawer }: any) => {
  const [tab, setTab] = useState(1);
  const [newGroup, showNewGroup] = useState(false);

  const selectUsers = () => {
    showNewGroup(true);
  };

  const UserList = user.map((m) => {
    return (
      <div className="flex " key={m.id}>
        <div className="py-2">
          <img
            src={m.profile_image}
            className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
          />
        </div>
        <div className="py-2 border-b border-gray-200 w-full my-auto">
          <div className="text-sm sm:text-base">
            {m.first_name} {m.last_name}{" "}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      {tab == 1 ? <ForwardChatComponent setOpenDrawer={setOpenDrawer} /> : null}
      {tab == 3 ? (
        <ForwardCalendarComponent setOpenDrawer={setOpenDrawer} />
      ) : null}
      <div className="flex absolute bottom-0 w-full items-center justify-center p-6 rounded-b">
        <button
          type="button"
          className={
            "py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 " +
            (tab === 1 ? "text-blue-500 ring-4 ring-gray-200" : "")
          }
          onClick={() => {
            setTab(1);
          }}
        >
          Chats
        </button>
        <button
          className="py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          type="button"
        >
          Contacts
        </button>
        <button
          className={
            "py-2.5 px-5 mr-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 " +
            (tab === 3 ? "text-blue-500 ring-4 ring-gray-200" : "")
          }
          type="button"
          onClick={() => {
            setTab(3);
          }}
        >
          Calender
        </button>
      </div>
    </div>
  );
};

export default ForwardComponent;
