import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { AuthState } from "../reducers/AuthReducer";
import { useNavigate } from "react-router-dom";
import {
  BsPersonCircle,
  BsFillSquareFill,
  BsFillTriangleFill,
  BsPersonBadgeFill,
} from "react-icons/bs";
import { IoIosChatbubbles } from "react-icons/io";

const Footer = () => {
  const path = window.location.href.split("/")[3];
  let navigate = useNavigate();

  const userAuth = useSelector<RootState, AuthState>((state) => state.userAuth);

  useEffect(() => {
    // console.log("path", path);
    // console.log("home-userinfo", userAuth.userInfo);
    if (!userAuth.userInfo) {
      navigate("/login");
    }
  }, [path]);

  return (
    <div
      className={`mx-auto flex justify-center bg-gray-200 text-white text-lg font-bold bottom-0 inset-x-0 fixed max-w-xl ${
        path === "login" || path === "signup" || path === "chatInbox"
          ? "hidden"
          : ""
      }`}
    >
      <div className="">
        <div className="flex space-x-4">
          <a
            href="/contacts"
            className={`text-slate-500 px-1 sm:px-3 py-2 rounded-md text-xs sm:text-sm font-medium hover:text-blue-700 focus:text-blue-700 ${
              path === "contacts" || path === "" ? "text-blue-700" : ""
            }`}
            aria-current="page"
          >
            <BsPersonCircle className="text-3xl m-auto pb-1" />
            Contacts
          </a>

          <a
            href="/savedlists"
            className={`text-slate-500 px-1 sm:px-3 py-2 rounded-md text-xs sm:text-sm font-medium hover:text-blue-700 focus:text-blue-700 ${
              path === "savedlists" ? "text-blue-700" : ""
            }`}
          >
            <BsFillSquareFill className="text-3xl m-auto pb-1" />
            Saved Lists
          </a>

          <a
            href="/chats"
            className={`text-slate-500 px-1 sm:px-3 py-2 rounded-md text-xs sm:text-sm font-medium hover:text-blue-700 focus:text-blue-700 ${
              path === "chats" ? "text-blue-700" : ""
            }`}
          >
            <IoIosChatbubbles className="text-3xl m-auto pb-1" />
            Chats
          </a>

          <a
            href="#"
            className={`text-slate-500 px-1 sm:px-3 py-2 rounded-md text-xs sm:text-sm font-medium hover:text-blue-700 focus:text-blue-700 ${
              path === "newsfeed" ? "text-blue-700" : ""
            }`}
          >
            <BsFillTriangleFill className="text-3xl m-auto pb-1" />
            News Feed
          </a>

          <a
            href="/profile"
            className={`text-slate-500 px-1 sm:px-3 py-2 rounded-md text-xs sm:text-sm font-medium hover:text-blue-700 focus:text-blue-700 ${
              path === "profile" ? "text-blue-700" : ""
            }`}
          >
            <BsPersonBadgeFill className="text-3xl m-auto pb-1" />
            Profile
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
