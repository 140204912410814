import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  BsFillBellFill,
  BsSearch,
  BsChevronLeft,
  BsBoxArrowRight,
  BsThreeDots,
} from 'react-icons/bs';
import TabsRender from './group-profile-tab';
import { RootState } from '../../../store';
import { ProfileState } from '../../../reducers/profileReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { leaveChannel } from '../../../actions/chatAction';
import { ChatState } from '../../../reducers/ChatReducer';

function GroupProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: Dispatch<any> = useDispatch();
  const [channelDetail, setChannelDetail] = React.useState(null);
  const [channelId, setChannelId] = React.useState(null);
  const [title, setTitle] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const chat = useSelector<RootState, ChatState>(
    (state: RootState) => state.chatData
  );

  useEffect(() => {
    Object.entries(location).map(([key, value]) => {
      if (key == 'state') {
        setChannelDetail(value.channelDetail);
        setChannelId(value.channelDetail.id);
        setTitle(value.channelDetail.name);
        setDescription(value.channelDetail.description);
      }
    });
  }, []);

  useEffect(() => {
    console.log('channelDetail', channelDetail);
  }, [channelDetail, setChannelDetail]);

  useEffect(() => {
    if (chat.leaveChannel && chat.leaveChannel.success == true) {
      navigate('/chats');
    }
  }, [chat]);

  const leaveGroup = () => {
    if (channelId) dispatch(leaveChannel(channelId));
  };

  return (
    <div>
      <div className='bg-gray-100'>
        <div className='flex items-center justify-center'>
          <div className='mt-10 rounded-lg'>
            <div className='flex justify-between'>
              <div
                className='text-blue-500 flex'
                onClick={() => {
                  navigate('/chats');
                }}
              >
                <BsChevronLeft className='text-3xl pb-1' />
                <span className='text-lg'>Back</span>
              </div>
            </div>
            <div className='flex items-center justify-center flex-col'>
              <img
                src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyuNFyw05KSucqjifL3PhDFrZLQh7QAS-DTw&usqp=CAU'
                className='rounded-full w-32 h-32'
              />
              <h1 className='text-gray-800 text-3xl mt-5'>
                {title ? title : 'Steam D'}
              </h1>
              <h1 className='text-gray-400 text-sm'>88 members, 20 online</h1>
            </div>

            <div className='flex items-center justify-center mt-3 mb-6 flex-col pr-[40px] pl-[40px]'>
              <div className='flex mt-2'>
                <div className='bg-white rounded-lg mr-1 sm:mr-2'>
                  <div className='m-2 rounded-lg p-1'>
                    <div className='box-border w-10 sm:w-16 text-center font-medium text-xs text-blue-500'>
                      <BsFillBellFill className='text-blue-500 text-2xl m-auto pb-1' />
                      <span>Mute</span>
                    </div>
                  </div>
                </div>
                <div className='bg-white rounded-lg mr-1 sm:mr-2'>
                  <div className='m-2 rounded-lg p-1'>
                    <div className='box-border w-10 sm:w-16 text-center font-medium text-xs text-blue-500'>
                      <BsSearch className='text-blue-500 text-2xl m-auto pb-1' />
                      <span>search</span>
                    </div>
                  </div>
                </div>
                <button
                  className='bg-white rounded-lg mr-1 sm:mr-2 hover:bg-gray-200 active:bg-gray-200 focus:outline-none focus:ring focus:ring-violet-300'
                  onClick={() => {
                    leaveGroup();
                  }}
                >
                  <div className='m-2 rounded-lg p-1'>
                    <div className='box-border w-10 sm:w-16 text-center font-medium text-xs text-blue-500'>
                      <BsBoxArrowRight className='text-blue-500 text-2xl m-auto pb-1' />
                      <span>leave</span>
                    </div>
                  </div>
                </button>
                <button className='bg-white rounded-lg mr-2 hover:bg-gray-200 active:bg-gray-200 focus:outline-none focus:ring focus:ring-violet-300'>
                  <div className='m-2 rounded-lg p-1'>
                    <div className='box-border h-10 w-10 sm:w-16 text-center font-medium text-xs text-blue-500'>
                      <BsThreeDots className='text-blue-500 text-2xl m-auto pb-1' />
                      <span>More</span>
                    </div>
                  </div>
                </button>
              </div>
            </div>

            <div className='flex mt-3 mb-6 flex-col bg-white p-2 h-[100px]'>
              <span>Group Descriptions: {description ? description : ''}</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <TabsRender />
      </div>
    </div>
  );
}
export default GroupProfile;
