function Contacts() {
  return (
    <div className="ml-8 sm:mx-12">
      <div className="flex ">
        <div className="py-2">
          <img
            src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
            className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
          />
        </div>
        <div className="py-2 border-b border-gray-200 w-full">
          <div className="grid">
            <span className="text-sm sm:text-base">Azore Trek April'22</span>
            <span className="text-xs text-gray-400">last seen just now</span>
          </div>
        </div>
      </div>
      <div className="flex ">
        <div className="py-2">
          <img
            src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
            className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
          />
        </div>
        <div className="py-2 border-b border-gray-200 w-full">
          <div className="grid">
            <span className="text-sm sm:text-base">Azore Trek April'22</span>
            <span className="text-xs text-gray-400">
              last seen 7 minutes ago
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
