import {
  ALL_CLUBS_LIST,
  ALL_DIRECTION_LIST,
  ALL_INTEREST_LIST,
  USER_CHANNELS_REQUEST,
  USER_CLUBS_DELETE,
  USER_CLUBS_REQUEST,
  USER_CLUBS_SAVE,
  USER_DETAILS_REQUEST,
  USER_DIRECTIONS_REQUEST,
  USER_DIRECTION_DELETE,
  USER_DIRECTION_SAVE,
  USER_EXPERIENCES_REQUEST,
  USER_EXPERIENCES_SAVE,
  USER_INTERESTS_REQUEST,
  USER_INTEREST_DELETE,
  USER_INTEREST_SAVE,
  USER_NOTES_DELETE,
  USER_NOTES_REQUEST,
  USER_NOTES_SAVE,
} from "../constants/userConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "../store";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getUserDetail =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const USER_DETAIL_URL = `${API_BASE_URL}/api/v1/users/me`;
    const response = await fetch(USER_DETAIL_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();
    const userData = {
      id: result.data.id,
      email: result.data.email,
      firstName: result.data.first_name,
      lastName: result.data.last_name,
    };
    localStorage.setItem("id", userData.id);
    dispatch({
      type: USER_DETAILS_REQUEST,
      payload: userData,
    });
  };

// === Profile Club Lists === //
export const getAllClubs =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const GET_URL = `${API_BASE_URL}/api/v1/general/get-all-resource/?resource=Club`;
    const response = await fetch(GET_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();
    const clubData = {
      type: "club",
      list: result.data.data,
    };
    dispatch({
      type: ALL_CLUBS_LIST,
      payload: clubData,
    });
  };

// === Profile Interests Lists === //
export const getAllInterest =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const GET_URL = `${API_BASE_URL}/api/v1/general/get-all-resource/?resource=Interest`;
    const response = await fetch(GET_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();
    const interest = {
      type: "interest",
      list: result.data.data,
    };
    dispatch({
      type: ALL_INTEREST_LIST,
      payload: interest,
    });
  };

// === Profile Direction Lists === //
export const getAllDirection =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const GET_URL = `${API_BASE_URL}/api/v1/general/get-all-resource/?resource=Direction`;
    const response = await fetch(GET_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();
    const direction = {
      type: "direction",
      list: result.data.data,
    };
    dispatch({
      type: ALL_DIRECTION_LIST,
      payload: direction,
    });
  };

// ===== Profile Note ===== //

// == Get Note == //
export const getNotes =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var id = localStorage.getItem("id");
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const USER_DETAIL_URL = `${API_BASE_URL}/api/v1/users/me/notes/${id}`;
    const response = await fetch(USER_DETAIL_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();

    dispatch({
      type: USER_NOTES_REQUEST,
      payload: result.data,
    });
  };

// == Add Note == //
export const addNote =
  (
    title: string,
    note: string,
    date: string
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const SAVE_CLUB_URL = `${API_BASE_URL}/api/v1/users/me/notes/store`;
      var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
      const response = await fetch(SAVE_CLUB_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token.value}`,
        },
        body: JSON.stringify({
          title,
          note,
          date,
        }),
      });

      const res = await response.json();
      const noteData = {
        id: res.id,
        title: res.title,
        note: res.note,
        date: res.date,
      };

      dispatch({
        type: USER_NOTES_SAVE,
        payload: noteData,
      });
    } catch (error) {}
  };

// === Delete === //
export const deleteNote =
  (id: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const USER_DETAIL_URL = `${API_BASE_URL}/api/v1/users/me/notes/${id}`;
    const response = await fetch(USER_DETAIL_URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();

    dispatch({
      type: USER_NOTES_DELETE,
      payload: result.data,
    });
  };

// ===== Profile Clubs ===== //

// === Get Clubs === //
export const getClubs =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var id = localStorage.getItem("id");
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const USER_DETAIL_URL = `${API_BASE_URL}/api/v1/users/me/clubs/${id}`;
    const response = await fetch(USER_DETAIL_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();

    dispatch({
      type: USER_CLUBS_REQUEST,
      payload: result.data,
    });
  };
// === Save Club === //
export const saveClub =
  (
    name: string,
    position: string
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const SAVE_CLUB_URL = `${API_BASE_URL}/api/v1/users/me/clubs/store`;
      var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
      const response = await fetch(SAVE_CLUB_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token.value}`,
        },
        body: JSON.stringify({
          name,
          position,
        }),
      });

      const data = await response.json();
      const clubData = {
        id: data.id,
        name: data.name,
        position: data.position,
      };

      dispatch({
        type: USER_CLUBS_SAVE,
        payload: clubData,
      });
    } catch (error) {}
  };

// === Delete === //
export const deleteClub =
  (id: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const CLUB_DELETE_URL = `${API_BASE_URL}/api/v1/users/me/clubs/${id}/detach`;
    const response = await fetch(CLUB_DELETE_URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();

    dispatch({
      type: USER_CLUBS_DELETE,
      payload: result.data,
    });
  };

// ===== Profile Experience ===== //

// === Get Experiences === //
export const getExperiences =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var id = localStorage.getItem("id");
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const USER_EXPERIENCES_URL = `${API_BASE_URL}/api/v1/users/me/experiences`;
    const response = await fetch(USER_EXPERIENCES_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();
    dispatch({
      type: USER_EXPERIENCES_REQUEST,
      payload: result.data,
    });
  };

// === Save Experiences === //

export const saveExperiences =
  (
    uni_name: string,
    concern: string,
    year: string,
    degree: string
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const SAVE_EXPERINCES_URL = `${API_BASE_URL}/api/v1/users/me/experiences  /store`;
      var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
      const response = await fetch(SAVE_EXPERINCES_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token.value}`,
        },
        body: JSON.stringify({
          uni_name,
          concern,
          year,
          degree,
        }),
      });

      const data = await response.json();
      const experienceData = {
        id: data.id,
        university_id: data.university.id,
        university_name: data.university.name,
        concern: data.concern,
        degree: data.degree,
        year: data.year,
      };

      dispatch({
        type: USER_EXPERIENCES_SAVE,
        payload: experienceData,
      });
    } catch (error) {}
  };

// ===== Profile Interest ===== //

// === Get Interest === //
export const getInterests =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var id = localStorage.getItem("id");
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const INTEREST_URL = `${API_BASE_URL}/api/v1/users/me/interests/${id}`;
    const response = await fetch(INTEREST_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();
    dispatch({
      type: USER_INTERESTS_REQUEST,
      payload: result.data,
    });
  };

// === Save Interest === //
export const saveInterest =
  (name: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const SAVE_INTEREST_URL = `${API_BASE_URL}/api/v1/users/me/interests/store`;
      var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
      const response = await fetch(SAVE_INTEREST_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token.value}`,
        },
        body: JSON.stringify({
          name,
        }),
      });

      const data = await response.json();
      const interestData = {
        name: data.name,
        id: data.id,
        prefixed_id: data.prefixed_id,
      };

      dispatch({
        type: USER_INTEREST_SAVE,
        payload: interestData,
      });
    } catch (error) {}
  };

// === Delete Interest=== //
export const deleteInterest =
  (id: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const INTEREST_DELETE_URL = `${API_BASE_URL}/api/v1/users/me/interests/${id}/detach`;
    const response = await fetch(INTEREST_DELETE_URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();

    dispatch({
      type: USER_INTEREST_DELETE,
      payload: result.data,
    });
  };

export const getChannels =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var id = localStorage.getItem("id");
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const USER_DETAIL_URL = `${API_BASE_URL}/api/v1/users/me/channels/${id}`;
    const response = await fetch(USER_DETAIL_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();
    dispatch({
      type: USER_CHANNELS_REQUEST,
      payload: result.data,
    });
  };

// ===== Profile Direction ===== //

// === Get Directions === //
export const getDirections =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var id = localStorage.getItem("id");
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const USER_DIRECTION_URL = `${API_BASE_URL}/api/v1/users/me/directions/${id}`;
    const response = await fetch(USER_DIRECTION_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();
    dispatch({
      type: USER_DIRECTIONS_REQUEST,
      payload: result.data,
    });
  };

// === Save Direction === //
export const saveDirection =
  (name: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const SAVE_DIRECTION_URL = `${API_BASE_URL}/api/v1/users/me/directions/store`;
      var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
      const response = await fetch(SAVE_DIRECTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token.value}`,
        },
        body: JSON.stringify({
          name,
        }),
      });

      const data = await response.json();
      const directionData = {
        id: data.id,
        name: data.name,
        position: data.position,
      };

      dispatch({
        type: USER_DIRECTION_SAVE,
        payload: directionData,
      });
    } catch (error) {}
  };

// === Delete Direction === //
export const deleteDirection =
  (id: string): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const DIRECTION_DELETE_URL = `${API_BASE_URL}/api/v1/users/me/directions/${id}/detach`;
    const response = await fetch(DIRECTION_DELETE_URL, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    const result = await response.json();

    dispatch({
      type: USER_DIRECTION_DELETE,
      payload: result.data,
    });
  };
