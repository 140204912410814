import { SyntheticEvent, useEffect } from "react";
// import { BsArrowRightShort } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../actions/userAction";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { RootState } from "../store";
import { AuthState } from "../reducers/AuthReducer";
import Input from "../components/Input";
import Button from "../components/Button";

interface FormData {
  uniName: string;
  email: string;
  password: string;
}

const LoginScreen = () => {
  const dispatch: Dispatch<any> = useDispatch();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: "onChange" });

  const userAuth = useSelector<RootState, AuthState>(
    (state: RootState) => state.userAuth
  );
  useEffect(() => {
    if (userAuth.userInfo) {
      navigate("/profile");
    }
  }, [userAuth, navigate]);

  const onSubmit = handleSubmit(({ uniName, email, password }) => {
    dispatch(login(uniName, email, password));
  });

  const SignupHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    navigate("/signup");
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
      <div className="max-w-sm w-full mx-auto">
        <div className="flex justify-center pb-12">
          <span className="text-center font-medium text-5xl bg-neutral-200 text-zinc-400 border-4 border-slate-200 pl-6 pr-6">
            Uni
          </span>
        </div>
        <div className="text-4xl text-gray-900 mt-2 ml-2">Welcome to Uni</div>
      </div>
      <div className="max-w-sm w-full mx-auto mt-10 bg-white border-gray-300">
        <form action="" className="space-y-6" onSubmit={onSubmit}>
          <div>
            <div className="flex items-center border-b border-slate-300 py-2">
              <Input
                register={register}
                name="email"
                type="text"
                placeholder="Email Address"
                ariaLabel="Email Address"
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
              />
            </div>
            <div className="mt-1 ml-2 text-red-700">
              {errors.email && "Email is invalid"}
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="flex items-center">
              <a href="/" className="font-medium text-sm text-gray-500">
                Forgot Password?
              </a>
            </div>
          </div>
          <div>
            <div className="flex items-center border-b border-slate-300 py-2">
              <Input
                register={register}
                name="password"
                type="password"
                placeholder="Password"
                ariaLabel="Password"
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
              />
            </div>
            <div className="mt-1 ml-2 text-red-700">
              {errors.password && "Password is invalid"}
            </div>
          </div>
          <div>
            <Button
              onClick=""
              type="submit"
              className=""
              span_style=""
              value="Sign In"
            />
          </div>
          <div>
            <Button
              onClick={SignupHandler}
              type="button"
              className=""
              span_style="ml-2 text-gray-400"
              value="Create Account"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
