import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Input from "../Input";
import { IoChatboxOutline } from "react-icons/io5";
import { Menu, Transition, Switch } from "@headlessui/react";
import Select from "../Select";
import Switchs from "../Switch";
import { useNavigate } from "react-router-dom";

const select1 = [
  { id: 1, type: "select", name: "Yes" },
  { id: 2, type: "select", name: "No" },
];
const selectDate = [
  { id: 1, type: "date", name: "2022" },
  { id: 2, type: "date", name: "2023" },
  { id: 3, type: "date", name: "2024" },
  { id: 4, type: "date", name: "2025" },
];

const NewGroupDetail = ({ setShowModal }: any) => {
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({ mode: "onChange" });

  const onSubmit = handleSubmit(() => {
    setShowModal(false);
  });

  const sendGroup = () => {
    navigate("/group-profile");
  };
  return (
    <div className="max-w-xl mx-auto flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="absolute bottom-0 w-full h-[95%] bg-white border-2 rounded-t-[20px]">
        <div className="pt-10 pb-2 flex justify-between bg-gray-100 h-[10%] rounded-xl">
          <button
            className="pl-6 text-blue-500 flex items-center"
            onClick={() => setShowModal(false)}
          >
            <span className="text-lg">Cancel</span>
          </button>
          <div className="text-center">
            <div className="text-xl">
              <span className="font-bold">New</span> Group
            </div>
          </div>
          <button
            className="pr-6 text-gray-500 flex items-center"
            onClick={() => {
              sendGroup();
            }}
          >
            <span className="text-lg">Send</span>
          </button>
        </div>

        <form
          action=""
          className="space-y-6 bg-gray-100 px-6 pt-6 h-[90%]"
          onSubmit={onSubmit}
        >
          <div>
            <div className="items-center py-2 ">
              <label className="pl-2 text-base">Group Name</label>
              <Input
                register={register}
                name="title"
                type="text"
                placeholder="Title"
                ariaLabel="Title"
                className="appearance-none bg-white rounded-[10px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="items-center pt-6 ">
              <label className="pl-2 text-base">Is this a Subgroup?</label>
              <div className="bg-white py-2 px-3 w-full rounded-[10px] grid">
                <Select
                  name="(Select Yes or No)"
                  value={select1}
                  className="flex justify-between w-full border-b border-slate-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 "
                />
                <div className="border-b border-slate-300 py-2">
                  <Input
                    register={register}
                    name="title"
                    type="text"
                    placeholder="Subgroup Of"
                    ariaLabel="Title"
                    className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-4 leading-tight focus:outline-none"
                  />
                </div>
                <div className="border-b border-slate-300 py-2">
                  <Switchs
                    title="Expires"
                    subtitle=""
                    className="grid pl-4 text-gray-400"
                  />
                </div>
                <Select
                  name="Expiration Date? Select"
                  value={selectDate}
                  className="flex justify-between w-full border-b border-slate-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-400 hover:bg-gray-50 "
                />
              </div>
            </div>
            <div className="items-center pt-10 ">
              <div className="bg-white py-2 px-3 w-full rounded-[10px] grid">
                <div className="border-b border-slate-300 py-2">
                  <Switchs
                    title="Make Public"
                    subtitle="If Closed, Anyone in the main group can see"
                    className="grid pl-4"
                  />
                </div>
                <div className="border-b border-slate-300 py-2">
                  <Switchs
                    title="Make Open"
                    subtitle="If Closed, Request Required to Join"
                    className="grid pl-4"
                  />
                </div>
                <Select
                  name="Google Tags(e.g.Entrepreneurship)"
                  value={select1}
                  className="flex justify-between w-full border-b border-slate-300 shadow-sm px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 "
                />
                <Select
                  name="Group Description"
                  value={selectDate}
                  className="flex justify-between w-full shadow-sm px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 "
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewGroupDetail;
