type Props = {
  onClick: any;
  type: string;
  value: string;
  className?: string;
  span_style?: string;
};

export default function Button({
  onClick,
  type,
  value,
  className = "",
  span_style,
}: Props) {
  return (
    <>
      {type === "submit" ? (
        <button
          type={type}
          className={
            `px-3 py-2 flex items-center text-3xl leading-snug hover:opacity-75 m-auto` +
            className
          }
        >
          <span className={span_style}>{value}</span>
        </button>
      ) : (
        <button
          className="px-3 py-2 flex items-center text-3xl leading-snug hover:opacity-75 m-auto"
          onClick={onClick}
        >
          <span className="ml-2 text-gray-400">Create Account</span>
        </button>
      )}
    </>
  );
}
