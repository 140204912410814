import React, { useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { IoPersonAddOutline } from "react-icons/io5";
import { BsGeoAlt, BsTree } from "react-icons/bs";
import NewGroupDetail from "../../components/Modals/NewGroupDetail";
import { useNavigate } from "react-router-dom";

const user = [
  {
    id: "usr_06fcc6632e",
    first_name: "Jeff",
    last_name: "Schowalter",
    email: "doyle.maxine@example.com",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+ex",
    description: "Aliquam eos rerum ipsa qui molestiae esse.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
  },
  {
    id: "usr_e551f9f1c9",
    first_name: "Arianna",
    last_name: "Langosh",
    email: "goldner.emmanuel@example.net",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+hic",
    description: "Natus quia laudantium odit qui facere qui.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
  },
];

export default function SearchResultComponent({ setOpenDrawer }: any) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const UserList = user.map((m) => {
    return (
      <div className="flex" key={m.id}>
        <div className="py-2">
          <img
            src={m.profile_image}
            className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
          />
        </div>
        <div className="py-2 pl-2 border-b border-gray-200 w-full flex justify-between">
          <div className="grid">
            <span className="text-sm sm:text-base">
              {m.first_name} {m.last_name}{" "}
            </span>
            <span className="text-xs text-gray-400">last seen just now</span>
          </div>
          <input
            className="w-3 h-6 rounded-md"
            value=""
            style={{ margin: "4px" }}
            type="checkbox"
          />
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="bg-white">
        <div className="sm:mx-4">
          <button
            className="flex text-blue-500 pl-2 w-full"
            onClick={() => setShowModal(true)}
          >
            <BsTree className="text-xl mr-2 my-3" />
            <div className="py-3 border-gray-200 border-b w-full text-left">
              <span>Invite to Group</span>
            </div>
          </button>
          <button className="flex text-blue-500 pl-2 w-full">
            <AiOutlineMessage className="text-xl mr-2 my-3" />
            <div className="py-3 border-gray-200 border-b w-full text-left">
              <span>Message Selected Contacts</span>
            </div>
          </button>
          <button
            className="flex text-blue-500 pl-2 w-full"
            onClick={() => setOpenDrawer(false)}
          >
            <BsGeoAlt className="text-xl mr-2 my-3" />
            <div className="py-3 border-gray-200 border-b w-full text-left">
              <span>Search Again</span>
            </div>
          </button>
          <button
            className="flex text-blue-500 pl-2 w-full"
            onClick={() => {
              navigate("/savedlists");
              setOpenDrawer(false);
            }}
          >
            <IoPersonAddOutline className="text-xl mr-2 my-3" />
            <div className="py-3 border-gray-200 border-b w-full text-left">
              <span>Save Search List</span>
            </div>
          </button>
        </div>
      </div>
      {UserList}
      {showModal == true ? (
        <NewGroupDetail setShowModal={setShowModal} />
      ) : null}
    </div>
  );
}
