import {
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_SUCCESS,
} from "../constants/userConstants";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { RootState } from "../store";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const login =
  (
    uniName: string,
    email: string,
    password: string
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const LOGIN_URL = `${API_BASE_URL}/api/v1/login`;
      const response = await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          uniName,
          email,
          password,
        }),
      });

      const data = await response.json();
      const userData = {
        accessState: data.success,
        message: data.message,
        token: data.data.access_token,
      };

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: userData,
      });
      localStorage.setItem("userInfo", JSON.stringify(userData.token));
    } catch (error) {
      //   dispatch({
      //     type: USER_LOGIN_FAIL,
      //     payload:
      //       error.response && error.response.data.message
      //         ? error.response.data.message
      //         : error.message,
      //   });
    }
  };
export const signup =
  (
    university_name: string,
    email: string,
    graduation_year: string,
    password: string
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      dispatch({
        type: USER_REGISTER_SUCCESS,
      });
      const SIGNUP_URL = `${API_BASE_URL}/api/v1/register`;
      const response = await fetch(SIGNUP_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          university_name,
          email,
          password,
          graduation_year,
        }),
      });

      const data = await response.json();
      const userData = {
        state: data.success,
        message: data.message,
        access_token: data.data.access_token,
        token_type: data.data.token_type,
      };

      dispatch({
        type: USER_REGISTER_SUCCESS,
        payload: userData,
      });
    } catch (error) {
      //   dispatch({
      //     type: USER_LOGIN_FAIL,
      //     payload:
      //       error.response && error.response.data.message
      //         ? error.response.data.message
      //         : error.message,
      //   });
    }
  };

export const logout =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    dispatch({
      type: USER_LOGOUT,
    });
    var token = { value: JSON.parse(localStorage.getItem("userInfo")!) };
    const LOGOUT_URL = `${API_BASE_URL}/api/v1/logout`;
    await fetch(LOGOUT_URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token.value}`,
        Accept: "application/json",
      },
    });
    localStorage.removeItem("userInfo");
  };
