import { autocompleteClasses } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

export default function TopDrawer({
  openDrawer,
  setOpenDrawer,
  children,
}: any) {
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (event.type === "click") {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <Drawer
      anchor={"top"}
      open={openDrawer}
      onClose={toggleDrawer(false)}
      ModalProps={{
        onBackdropClick: () => {
          setOpenDrawer(false);
        },
      }}
      PaperProps={{
        sx: {
          width: "90%",
          maxWidth: 800,
          marginLeft: "auto",
          marginRight: "auto",
        },
      }}
      style={{
        borderRadius: 20,
        position: "relative",
      }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 32,
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        {children}
      </Box>
    </Drawer>
  );
}
