import {
  useState,
  Fragment,
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
} from "react";
import { Combobox, Transition } from "@headlessui/react";
import { BsCheck2, BsChevronLeft } from "react-icons/bs";

type Props = {
  list: any;
  name: string;
  className?: string;
  span_style?: string;
  setSelectedItem: any;
};
const ComboBox = ({ list, setSelectedItem }: Props) => {
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState("");

  const filteredName =
    query === ""
      ? list.list
      : list.list.filter((person: { name: string }) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  useEffect(() => {
    const data = { selected, type: list.type };
    setSelectedItem(data);
  }, [selected, setSelected]);

  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative mt-6 mx-6">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left  focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2  sm:text-sm">
          <Combobox.Button className="absolute inset-y-0 left-2 flex items-center pr-2">
            <BsChevronLeft
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
          <Combobox.Input
            placeholder={"Search "}
            className="w-full border-none py-2 pl-8 pr-3 text-sm leading-5 text-gray-900 focus:ring-0 focus:bg-gray-200 focus:outline-none"
            displayValue={(person: any) => person.name}
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options
            className={
              "z-10 absolute max-h-60 w-full bg-gray-200 mt-1 px-3 overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              // (filteredName.length !== 0 ? "" : " mt-1")
            }
          >
            {filteredName.length === 0 && query !== "" ? (
              <div className="relative text-left cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredName.map(
                (person: {
                  id: Key | null | undefined;
                  name:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | ReactFragment
                    | ReactPortal
                    | null
                    | undefined;
                }) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 px-4 border-b border-gray-300 ${
                        active ? "bg-gray-300 text-gray-800" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate text-left ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {person.name}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                )
              )
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default ComboBox;
