import { useRef } from "react";
import { useForm } from "react-hook-form";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signup } from "../actions/userAction";
import Input from "../components/Input";
import Button from "../components/Button";

interface FormData {
  uniName: string;
  email: string;
  graduationYear: string;
  password: string;
  confirmPassword: string;
}

const SignupScreen = () => {
  const dispatch: Dispatch<any> = useDispatch();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({ mode: "onChange" });

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = handleSubmit(
    ({ uniName, email, graduationYear, password }) => {
      dispatch(signup(uniName, email, graduationYear, password));
      navigate("/login");
    }
  );
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center">
      <div className="max-w-sm w-full mx-auto">
        <div className="flex justify-center pb-12">
          <span className="text-center font-medium text-5xl bg-neutral-200 text-zinc-400 border-4 border-slate-200 pl-6 pr-6">
            Uni
          </span>
        </div>
        <div className="text-4xl text-gray-900 mt-2 ml-2">Welcome to Uni</div>
      </div>
      <div className="max-w-sm w-full mx-auto mt-10 bg-white border-gray-300">
        <form action="" className="space-y-6" onSubmit={onSubmit}>
          <div>
            <div className="flex items-center border-b border-slate-300 py-2">
              <Input
                register={register}
                name="uniName"
                type="text"
                placeholder="University Name"
                ariaLabel="University Name"
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
              />
            </div>
            <div className="mt-1 ml-2 text-red-700">
              {errors.uniName && "University Name is required"}
            </div>
          </div>
          <div>
            <div className="flex items-center border-b border-slate-300 py-2">
              <Input
                register={register}
                name="email"
                type="text"
                placeholder="Email Address"
                ariaLabel="Email Address"
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
              />
            </div>
            <div className="mt-1 ml-2 text-red-700">
              {errors.email && "Email is invalid"}
            </div>
          </div>
          <div>
            <div className="flex items-center border-b border-slate-300 py-2">
              <Input
                register={register}
                name="graduationYear"
                type="text"
                placeholder="Class of (Graduation Year)"
                ariaLabel="Graduation Year"
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
              />
            </div>
            <div className="mt-1 ml-2 text-red-700">
              {errors.graduationYear && "Graduation Year is required"}
            </div>
          </div>
          <div>
            <div className="flex items-center border-b border-slate-300 py-2">
              <Input
                register={register}
                name="password"
                type="password"
                placeholder="Password"
                ariaLabel="Password"
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
              />
            </div>
            <div className="mt-1 ml-2 text-red-700">
              {errors.password && "Password is required"}
            </div>
          </div>
          <div>
            <div className="flex items-center border-b border-slate-300 py-2">
              <input
                {...register("confirmPassword", {
                  required: true,
                  minLength: 4,
                  maxLength: 30,
                  validate: (value) =>
                    value === password.current || "The passwords do not match",
                })}
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                aria-label="Confirm Password"
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
              />
            </div>
            <div className="mt-1 ml-2 text-red-700">
              {errors.confirmPassword && errors.confirmPassword.message}
            </div>
          </div>
          <div>
            <Button
              onClick=""
              type="submit"
              className=""
              span_style="ml-2 text-gray-400"
              value="Confirm Account Creation"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupScreen;
