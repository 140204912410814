import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import Modal from '../../components/Modals/Select-Modal';
import {
  getClubs,
  getInterests,
  getDirections,
  getNotes,
  deleteNote,
  deleteClub,
  deleteInterest,
  deleteDirection,
  getExperiences,
  saveExperiences,
  getAllClubs,
} from '../../actions/profileAction';

import { RootState } from '../../store';
import { ProfileState } from '../../reducers/profileReducer';
import ProfileNoteModal from '../../components/Modals/Note-Modal';
import { BsX } from 'react-icons/bs';

const tab = [
  { id: 1, name: 'Notes' },
  { id: 2, name: 'Media' },
  { id: 3, name: 'Clubs' },
  { id: 4, name: 'Experience' },
  { id: 5, name: 'Direction' },
  { id: 6, name: 'Interests' },
];

const universityLists = [
  { id: 1, type: 'university', name: 'Dartmouth College, Hanover NH' },
  {
    id: 2,
    type: 'university',
    name: 'University of Dartmouth, Dartmouth MA',
  },
];

const majorList = [
  { id: 1, type: 'major', name: 'Neurobiology' },
  { id: 2, type: 'major', name: 'Neuroscience' },
];

const graduationYear = [
  { id: 1, type: 'graduation', name: '2017' },
  { id: 2, type: 'graduation', name: '2018' },
  { id: 3, type: 'graduation', name: '2019' },
  { id: 4, type: 'graduation', name: '2020' },
  { id: 5, type: 'graduation', name: '2021' },
  { id: 6, type: 'graduation', name: '2022' },
];

const degreeList = [
  { id: 1, type: 'degree', name: 'DG' },
  { id: 2, type: 'degree', name: 'AB' },
  { id: 3, type: 'degree', name: 'BA' },
  { id: 4, type: 'degree', name: 'BS' },
  { id: 5, type: 'degree', name: 'EMBA' },
];

const Tabs = (data: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [openTab, setOpenTab] = React.useState(0);
  const [experience, setExperience] = React.useState<any>(null);
  const [userId, setUserId] = React.useState(null);
  const profileData = useSelector<RootState, ProfileState>(
    (state: RootState) => state.userData
  );

  useEffect(() => {
    if (openTab == 1) {
      dispatch(getNotes());
      // dispatch(getOtherNotes());
    } else if (openTab == 3) {
      dispatch(getClubs());
      // dispatch(getOtherClubs());
    } else if (openTab == 4) {
      dispatch(getExperiences());
      // dispatch(getOtherExperiences());
    } else if (openTab == 5) {
      dispatch(getDirections());
      // dispatch(getOtherDirections());
    } else if (openTab == 6) {
      dispatch(getInterests());
      // dispatch(getOtherInterests());
    }
  }, [openTab]);

  useEffect(() => {
    setUserId(data.userId);
  }, []);

  const addExperience = () => {
    if (experience)
      dispatch(
        saveExperiences(
          experience?.university,
          experience?.major,
          experience?.graduation,
          experience?.degree
        )
      );
  };

  const EducationData = [
    universityLists,
    majorList,
    graduationYear,
    degreeList,
  ];
  // const InterestsData = [interestLists];
  // const DirectionList = [directionLists];
  // const ClubsList = [clubLists, positionLists];

  const removeNote = (id: any) => {
    dispatch(deleteNote(id));
  };
  const removeClub = (id: any) => {
    dispatch(deleteClub(id));
  };
  const removeInterest = (id: any) => {
    dispatch(deleteInterest(id));
  };
  const removeDirection = (id: any) => {
    dispatch(deleteDirection(id));
  };

  const TabElement = tab.map((m) => {
    return (
      <li
        className='-mb-px sm:mr-2 last:mr-0 flex-auto text-center'
        key={m.name}
      >
        <a
          className={
            'text-xs font-bold px-2 py-2 rounded block leading-normal ' +
            (openTab === m.id && data.openMessage !== 1
              ? 'text-blue-500 border-b-4 border-blue-500'
              : 'text-gray-400')
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(m.id);
          }}
          data-toggle='tab'
          href={`#${m.name}`}
          role='tablist'
        >
          {m.name}
        </a>
      </li>
    );
  });

  const NoteElement = profileData.noteData?.map((m) => {
    return (
      <div className='flex text-xs justify-between' key={m.id}>
        <div className='flex'>
          <div className='font-bold mr-2'>
            <span>{m.date}</span>
          </div>
          <div className='grid text-xs'>
            <span className='font-bold'>{m.title}</span>
            <span>{m.note}</span>
            {/* <span className="italic">Click to Expand</span> */}
          </div>
        </div>

        <button
          onClick={() => {
            removeNote(m.id);
          }}
        >
          <BsX className='text-base hover:bg-sky-400 rounded-full hover:text-white' />
        </button>
      </div>
    );
  });

  const ClubElement = profileData.clubData?.map((club) => {
    return (
      <div className='pb-4' key={club.id}>
        <div className='p-2 bg-blue-500 text-white rounded-xl flex justify-between text-xs'>
          <div className='grid'>
            <span>Club e.g. {club.name}</span>
            <span>Position e.g.{club.position}</span>
          </div>
          <button
            onClick={() => {
              removeClub(club.id);
            }}
          >
            <BsX className='text-base hover:bg-sky-400 rounded-full hover:text-white' />
          </button>
        </div>
      </div>
    );
  });

  const InterestElement = profileData.interestData?.map((interest) => {
    return (
      <div className='pb-4' key={interest.id}>
        <div className='p-2 bg-blue-500 text-white rounded-xl flex justify-between text-xs'>
          <span>{interest.name}</span>
          <button
            onClick={() => {
              removeInterest(interest.id);
            }}
          >
            <BsX className='text-base hover:bg-sky-400 rounded-full hover:text-white' />
          </button>
        </div>
      </div>
    );
  });

  const DirectionElement = profileData.directionData?.map((direction) => {
    return (
      <div className='pb-4' key={direction.id}>
        <div className='p-2 bg-blue-500 text-white rounded-xl flex justify-between text-xs'>
          <span>{direction.name}</span>
          <button
            onClick={() => {
              removeDirection(direction.id);
            }}
          >
            <BsX className='text-base hover:bg-sky-400 rounded-full hover:text-white' />
          </button>
        </div>
      </div>
    );
  });

  return (
    <>
      <div className='flex flex-wrap'>
        <div className='w-full'>
          <ul
            className='flex mb-0 list-none flex-wrap pt-3 pb-4 px-1 sm:px-3 flex-row'
            role='tablist'
          >
            {TabElement}
          </ul>
          <div className='relative flex flex-col min-w-0 break-words bg-white w-full mb-16 rounded'>
            <div className='px-2 sm:px-8 py-2 sm:py-5 flex-auto'>
              <div className='tab-content tab-space'>
                <div
                  className={data.openMessage === 1 ? 'block' : 'hidden'}
                  id='openMessage'
                >
                  <div className='flex text-xs'>
                    <div className='font-bold mr-2'>
                      <span>January 31 2022</span>
                    </div>
                    <div className='grid text-xs'>
                      <span className='font-bold'>
                        Saved Message Title or First Line
                      </span>
                      <span>
                        Note Preview eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Quis ipsum suspendisse ultrices
                        gravida. Risus
                      </span>
                      <span className='italic'>Click to Expand</span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    openTab === 1 && data.openMessage !== 1 ? 'block' : 'hidden'
                  }
                  id='Notes'
                >
                  {data.userId ? <div></div> : <ProfileNoteModal />}
                  {NoteElement}
                </div>
                <div
                  className={
                    openTab === 2 && data.openMessage !== 1 ? 'block' : 'hidden'
                  }
                  id='Media'
                >
                  <p>Media</p>
                </div>
                <div
                  className={
                    openTab === 3 && data.openMessage !== 1 ? 'block' : 'hidden'
                  }
                  id='Clubs'
                >
                  <div>
                    {ClubElement}
                    {data.userId && profileData.clublists != [''] ? (
                      <div></div>
                    ) : (
                      <div className='text-center'>
                        <Modal
                          list={['Clubs', 'Positions']}
                          data={[profileData.clublists]}
                          className='text-gray-500'
                          text='Add or Edit My Clubs'
                          setExperience={setExperience}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    openTab === 4 && data.openMessage !== 1 ? 'block' : 'hidden'
                  }
                  id='Experience'
                >
                  {data.userId ? (
                    <div>
                      <div className='pb-2'>
                        <div className='grid text-xs'>
                          <span className='font-bold'>Education:</span>
                          {profileData && profileData?.experienceData ? (
                            profileData?.experienceData?.map((m) => {
                              return (
                                <span className='pb-2' key={m.id}>
                                  {m.university.name}, {m.degree}, {m.concern},{' '}
                                  {m.year}
                                </span>
                              );
                            })
                          ) : experience ? (
                            <span className='pb-2'>
                              {experience?.university}, {experience?.degree},{' '}
                              {experience?.major}, {experience?.graduation}
                            </span>
                          ) : (
                            <span className='pb-2'></span>
                          )}

                          <span className='font-bold'>Professional:</span>
                          <span className='pb-2'>
                            Emergency Medical Technician, Dartmouth EMS,
                            2012-2015
                          </span>

                          <span className='font-bold'>Other Experience:</span>
                          <span className='pb-2'>
                            Optional place to put other skills, Sports,
                            Volunteer work etc.
                          </span>

                          <span className='font-bold'>Industry Tags:</span>
                          <span className='pb-2'>
                            Medicine, Entrepreneurship, CPG
                          </span>
                        </div>
                      </div>

                      <div className='pb-10 flex justify-between '>
                        <button className='bg-blue-500 text-white p-2 rounded-md'>
                          Upload Resume
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className='pb-2'>
                        <div className='grid text-xs'>
                          <span className='font-bold'>
                            Education:
                            <Modal
                              list={[
                                'University',
                                'Major/Concentration',
                                'Graduation Year',
                                'Degree',
                              ]}
                              data={EducationData}
                              text='Edit'
                              className='text-blue-500 active:bg-blue-600 font-bold outline-none focus:outline-none ml-1 mb-1'
                              setExperience={setExperience}
                            />
                          </span>
                          {profileData && profileData?.experienceData ? (
                            profileData?.experienceData?.map((m) => {
                              return (
                                <span className='pb-2' key={m.id}>
                                  {m.university.name}, {m.degree}, {m.concern},{' '}
                                  {m.year}
                                </span>
                              );
                            })
                          ) : // <div></div>
                          experience ? (
                            <span className='pb-2'>
                              {experience?.university}, {experience?.degree},{' '}
                              {experience?.major}, {experience?.graduation}
                            </span>
                          ) : (
                            <span className='pb-2'></span>
                          )}

                          <span className='font-bold'>
                            Professional: <a className='text-blue-500'>Save</a>
                          </span>
                          <span className='pb-2'>
                            Emergency Medical Technician, Dartmouth EMS,
                            2012-2015
                          </span>

                          <span className='font-bold'>
                            Other Experience:{' '}
                            <a className='text-blue-500'>Edit</a>
                          </span>
                          <span className='pb-2'>
                            Optional place to put other skills, Sports,
                            Volunteer work etc.
                          </span>

                          <span className='font-bold'>
                            Industry Tags: <a className='text-blue-500'>Edit</a>
                          </span>
                          <span className='pb-2'>
                            Medicine, Entrepreneurship, CPG
                          </span>
                        </div>
                      </div>

                      <div className='pb-10 flex justify-between '>
                        <button className='bg-blue-500 text-white p-2 rounded-md'>
                          Upload Resume
                        </button>
                        <button
                          className='bg-blue-500 text-white p-2 rounded-md'
                          onClick={() => addExperience()}
                        >
                          Save My Experience
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    openTab === 5 && data.openMessage !== 1 ? 'block' : 'hidden'
                  }
                  id='Direction'
                >
                  <div>
                    {DirectionElement}
                    {data.userId ? (
                      <div></div>
                    ) : (
                      <div className='text-center'>
                        <Modal
                          list={['Direction']}
                          data={[profileData.directionLists]}
                          className='text-gray-500'
                          text='Add or Edit My Direction'
                          setExperience={setExperience}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    openTab === 6 && data.openMessage !== 1 ? 'block' : 'hidden'
                  }
                  id='Interests'
                >
                  <div>
                    {InterestElement}
                    {data.userId ? (
                      <div></div>
                    ) : (
                      <div className='text-center'>
                        <Modal
                          list={['Interests']}
                          data={[profileData.interestLists]}
                          className='text-gray-500'
                          text='Add or Edit My Interests'
                          setExperience={setExperience}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function TabsRender(data: any) {
  return (
    <>
      <Tabs openMessage={data.openMessage} userId={data.userId} />
    </>
  );
}
