import { useState } from "react";
import { Switch } from "@headlessui/react";

type Props = {
  title: string;
  subtitle?: string;
  className: string;
};

export default function Switchs({ title, subtitle, className }: Props) {
  const [enabled, setEnabled] = useState(false);

  return (
    <Switch.Group>
      <div className="flex justify-between items-center">
        <Switch.Label className={className}>
          <span>{title}</span>
          <span>{subtitle}</span>
        </Switch.Label>
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={`${
            enabled ? "bg-green-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            className={`${
              enabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}
