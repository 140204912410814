type Props = {
  register: any;
  // errors: any;
  name: string;
  type: string;
  placeholder: string;
  ariaLabel: string;
  className?: string;
};

export default function Input({
  register,
  // errors,
  name,
  type,
  placeholder,
  ariaLabel,
  className,
}: Props) {
  return (
    <input
      {...register(name, {
        required: true,
        minLength: 4,
      })}
      type={type}
      name={name}
      placeholder={placeholder}
      aria-label={ariaLabel}
      className={className}
    />
  );
}
