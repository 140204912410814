import { useState } from "react";
import { BiGroup } from "react-icons/bi";
import { IoPersonAddOutline } from "react-icons/io5";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import NewGroup from "../../../components/Modals/NewGroup";

const user = [
  {
    id: "usr_06fcc6632e",
    first_name: "Jeff",
    last_name: "Schowalter",
    email: "doyle.maxine@example.com",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+ex",
    description: "Aliquam eos rerum ipsa qui molestiae esse.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
  },
  {
    id: "usr_e551f9f1c9",
    first_name: "Arianna",
    last_name: "Langosh",
    email: "goldner.emmanuel@example.net",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+hic",
    description: "Natus quia laudantium odit qui facere qui.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
  },
];

const NewMessage = ({ showNewMessage, showNewGroup }: any) => {
  const selectNewGroup = () => {
    // showNewMessage(false);
    showNewGroup(true);
  };

  const UserList = user.map((m) => {
    return (
      <div className="flex " key={m.id}>
        <div className="py-2">
          <img
            src={m.profile_image}
            className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
          />
        </div>
        <div className="py-2 border-b border-gray-200 w-full">
          <div className="grid">
            <span className="text-sm sm:text-base">
              {m.first_name} {m.last_name}{" "}
            </span>
            <span className="text-xs text-gray-400">last seen just now</span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="max-w-xl mx-auto flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="absolute bottom-0 w-full h-[95%] bg-white border-2 rounded-t-[20px]">
        <div>
          <div className="pt-10 pb-2 bg-gray-100 h-[10%] relative rounded-tl-[20px] rounded-tr-[20px]">
            <button
              className="absolute pl-6 text-blue-500 flex items-center"
              onClick={() => {
                showNewMessage(false);
              }}
            >
              <span className="text-lg">Cancel</span>
            </button>
            <div className="text-center">
              <div className="text-xl font-bold">New Message</div>
            </div>
          </div>
          <div className="pt-2 pb-2 relative mx-auto text-gray-600 text-center border-b border-gray-200 bg-gray-100">
            <input
              className="w-4/5 border-2 bg-gray-200 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
              type="search"
              name="search"
              placeholder="Search"
            />
          </div>
          <div>
            <div className="ml-8 sm:mx-12">
              <button
                className="flex text-blue-500 pl-2 w-full"
                onClick={() => {
                  selectNewGroup();
                }}
              >
                <BiGroup className="text-xl mr-2 my-3" />
                <div className="py-3 border-gray-200 border-b w-full text-left">
                  <span>New Group</span>
                </div>
              </button>
              <button className="flex text-blue-500 pl-2 w-full">
                <IoPersonAddOutline className="text-xl mr-2 my-3" />
                <div className="py-3 border-gray-200 border-b w-full text-left">
                  <span>New Message</span>
                </div>
              </button>
              <button className="flex text-blue-500 pl-2 w-full">
                <HiOutlineSpeakerphone className="text-xl mr-2 my-3" />
                <div className="py-3 border-gray-200 border-b w-full text-left">
                  <span>New Story</span>
                </div>
              </button>
            </div>
          </div>
          <div className="ml-8 sm:mx-12">{UserList}</div>
        </div>
      </div>
    </div>
  );
};

export default NewMessage;
