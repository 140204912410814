import { useState } from "react";
import { BsBoxArrowUp } from "react-icons/bs";
import {
  IoArrowRedoOutline,
  IoChatboxOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import NewGroupDetail from "../../components/Modals/NewGroupDetail";

function Toolbox({ setActive, setSelected }: any) {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 absolute bottom-0 px-4 pt-4 pb-8 w-full flex justify-around h-[10%]">
      <IoTrashOutline className="text-3xl text-blue-500 pt-1 mr-2" />
      <IoChatboxOutline
        className="text-3xl text-blue-500 pt-1 mr-2"
        onClick={() => setShowModal(true)}
      />
      <BsBoxArrowUp className="text-3xl text-blue-500 pt-1 mr-2" />
      <IoArrowRedoOutline
        className="text-3xl text-blue-500 pt-1 ml-2"
        onClick={() => {
          setActive(0);
          setSelected(1);
        }}
      />
      {showModal == true ? (
        <NewGroupDetail setShowModal={setShowModal} />
      ) : null}
    </div>
  );
}
export default Toolbox;
