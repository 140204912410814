import { useState, useEffect } from 'react';
import NewGroupDetail from '../../../components/Modals/NewGroup';

const user = [
  {
    id: 'usr_4e860939d0',
    first_name: 'Jeff',
    last_name: 'Schowalter',
    email: 'doyle.maxine@example.com',
    profile_image:
      'https://via.placeholder.com/640x480.png/0044ee?text=animals+ex',
    description: 'Aliquam eos rerum ipsa qui molestiae esse.',
    created_at: '2022-06-08T17:20:10.000000Z',
    updated_at: '2022-06-08T17:20:10.000000Z',
    checked: false,
  },
];

const NewGroup = ({
  showNewGroup,
  showNewGroupDetail,
  setCheckedUser,
}: any) => {
  const [users, selectUsers] = useState(user);

  const Back = () => {
    showNewGroup(false);
  };

  const selectGroupDetail = () => {
    showNewGroupDetail(true);
  };

  useEffect(() => {
    // console.log("user data", users);
    setCheckedUser(users.filter((ele) => (ele.checked = true)));
  }, [users, selectUsers]);

  const handleChange = (id: any) => {
    const copyUsers = [...users];
    const modifiedUsers = copyUsers.map((mUser) => {
      if (id === mUser.id) {
        mUser.checked = !mUser.checked;
      }

      return mUser;
    });

    selectUsers(modifiedUsers);
  };

  const UserList = user.map((m) => {
    return (
      <div className='flex' key={m.id}>
        <input
          type='checkbox'
          className='w-6 h-6 rounded-md m-[16px]'
          id={`user-${m.id}`}
          // checked={m.checked}
          onChange={() => handleChange(m.id)}
        />
        <div className='py-2'>
          <img
            src={m.profile_image}
            className='rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4'
          />
        </div>
        <div className='py-2 border-b border-gray-200 w-full'>
          <div className='grid'>
            <label className='text-sm sm:text-base' htmlFor={`user-${m.id}`}>
              {m.first_name} {m.last_name}
            </label>
            <span className='text-xs text-gray-400'>last seen just now</span>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className='max-w-xl mx-auto flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
      <div className='absolute bottom-0 w-full h-[95%] bg-white border-2 rounded-t-[20px]'>
        <div>
          <div className='pt-10 pb-2 bg-gray-100 h-[10%] relative border-b border-gray-200 rounded-tl-[20px] rounded-tr-[20px]'>
            <button
              className='absolute pl-6 text-blue-500 flex items-center'
              onClick={() => {
                Back();
              }}
            >
              <span className='text-lg'>Back</span>
            </button>
            <div className='text-center'>
              <div className='text-xl font-bold'>New Group</div>
              <div className='text-base font-gray-700'>0/200000</div>
            </div>
            <button
              className='absolute right-[20px] top-[40px] text-gray-500 flex items-center'
              onClick={() => {
                selectGroupDetail();
              }}
            >
              <span className='text-lg'>Next</span>
            </button>
          </div>
          <div className='relative mx-auto text-gray-600 text-center border-b border-gray-200 bg-gray-100'>
            <input
              className='w-full h-10 px-5 bg-gray-100 pr-16 text-sm focus:outline-none'
              type='search'
              name='search'
              placeholder='Who would you like to add?'
            />
          </div>
          <div className='pt-4'>{UserList}</div>
        </div>
      </div>
    </div>
  );
};

export default NewGroup;
