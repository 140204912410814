import { useState, useRef, useEffect } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { logout } from '../actions/userAction';

const Menu = ({}) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [show, setShow] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  let navigate = useNavigate();

  const handleBlurTransition = (evt: any) => {
    setTimeout(() => {
      setShow(false);
    }, 100);
  };

  const toggleMorePopup = () => {
    setShow(!show);
  };

  const myGroup = () => {
    navigate('/group', { state: { id: 1 } });
  };

  const signout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (show) {
      document.getElementById('more_transition_content')?.focus();
    }
    const handleEscape = (event: KeyboardEvent) => {
      if (!show) return;

      if (event.key === 'Escape') {
        setShow(false);
      }
    };

    document.addEventListener('keyup', handleEscape);
    return () => document.removeEventListener('keyup', handleEscape);
  }, [show]);

  return (
    <div ref={container} className='relative'>
      <button
        className='bg-white rounded-lg mr-2 hover:bg-gray-400 active:bg-gray-400 focus:outline-none focus:ring focus:ring-violet-300 ...'
        onClick={toggleMorePopup}
      >
        <div className='m-2 rounded-lg p-1'>
          <div className='box-border h-10 w-10 sm:w-16 text-center font-medium text-xs text-blue-500'>
            <BsThreeDots className='text-blue-500 text-2xl m-auto pb-1' />
            <span>More</span>
          </div>
        </div>
      </button>

      <div
        id='more_transition_content'
        tabIndex={1}
        onBlur={handleBlurTransition}
        className={`origin-top-right absolute right-0 w-52 py-2 mt-1 bg-white rounded-md shadow-md ${
          show ? '' : 'hidden'
        }`}
      >
        <a
          className='text-sm flex justify-between block px-4 py-2 border-b border-gray-200 hover:bg-blue-500 hover:text-green-100'
          //   onClick={}
        >
          <div>Group Invites</div>
          <div>1 Pending</div>
        </a>
        <a
          className='text-sm flex justify-between block px-4 py-2 hover:bg-blue-500 hover:text-green-100'
          onClick={myGroup}
        >
          <div className='mt-auto mb-auto'>My Groups</div>
          <div className='grid text-xs'>
            <span>12 Active</span>
            <span>17 Inactive</span>
          </div>
        </a>
        <a
          className='text-sm flex justify-between block px-4 py-2 hover:bg-blue-500 hover:text-green-100'
          onClick={signout}
        >
          <div className='mt-auto mb-auto'>Log out</div>
        </a>
      </div>
    </div>
  );
};

export default Menu;
