import {
  BsChevronLeft,
  BsFillSquareFill,
  BsGeoAlt,
  BsPin,
  BsPlusLg,
} from "react-icons/bs";
import { FiPaperclip, FiMic } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function ChatForm() {
  const navigate = useNavigate();

  return (
    <div className="bg-gray-100 absolute bottom-0 px-4 pt-4 pb-8 w-full flex h-[10%]">
      <FiPaperclip className="text-3xl text-gray-500 pt-1 mr-2" />
      <input
        className="w-full border-2 bg-white h-10 px-5 pr-16 rounded-full text-sm focus:outline-none"
        type="message"
        name="message"
        placeholder="Message"
      />
      <FiMic className="text-3xl text-gray-500 pt-1 ml-2" />
    </div>
  );
}
export default ChatForm;
