import React, { useEffect } from "react";
import { BsFolder2, BsChevronRight, BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ViewGroup from "./ViewGroup";

function SavedLists() {
  const [group, setGroupId] = React.useState(null);
  let navigate = useNavigate();

  const viewGroup = (id: any) => {
    setGroupId(id);
  };

  useEffect(() => {
    console.log("groups", group);
  }, [group]);

  return (
    <div>
      {group ? (
        <ViewGroup />
      ) : (
        <div className="bg-gray-100 px-2 sm:px-12">
          <div className="flex justify-between py-3 mt-1 pl-2 pr-2">
            <span className="text-3xl text-gray-500">All Saved Lists</span>
            <BsChevronRight className="text-amber-500 text-xs font-bold my-auto" />
          </div>
          <div className="px-2 rounded-md bg-white">
            <div
              className="flex"
              onClick={() => {
                viewGroup(1);
              }}
            >
              <div className="py-2">
                <BsFolder2 className="w-8 h-8 sm:w-8 sm:h-8 mr-2 sm:mr-4 text-yellow-400" />
              </div>
              <div className="flex justify-between border-b border-gray-200 w-full">
                <div className="grid">
                  <span className="text-sm sm:text-base">1/17/22</span>
                  <span className="text-sm sm:text-base">MBA 2023</span>
                </div>
                <div className="flex">
                  <span className="text-gray-500 m-auto">685</span>
                  <BsChevronRight className="text-gray-500 m-auto text-xs ml-1" />
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="py-2">
                <BsFolder2 className="w-8 h-8 sm:w-8 sm:h-8 mr-2 sm:mr-4 text-yellow-400" />
              </div>
              <div className="flex justify-between border-b border-gray-200 w-full">
                <div className="grid">
                  <span className="text-sm sm:text-base">1/16/22</span>
                  <span className="text-sm sm:text-base">
                    MBA MiM MiF 2023 2022
                  </span>
                </div>
                <div className="flex">
                  <span className="text-gray-500 m-auto">504</span>
                  <BsChevronRight className="text-gray-500 m-auto text-xs ml-1" />
                </div>
              </div>
            </div>
            <div className="flex ">
              <div className="py-2">
                <BsTrash className="w-8 h-8 sm:w-8 sm:h-8 mr-2 sm:mr-4 text-yellow-400" />
              </div>
              <div className="flex justify-between border-b border-gray-200 w-full">
                <span className="text-sm sm:text-base my-auto">
                  Recently Deleted
                </span>
                <div className="flex">
                  <span className="text-gray-500 m-auto">6 </span>
                  <BsChevronRight className="text-gray-500 m-auto text-xs ml-1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SavedLists;
