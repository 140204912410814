import {} from '../constants/chatConstants';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../store';
import { GET_FRIENDS } from '../constants/friendConstants';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getFriends =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem('userInfo')!) };
    const API_URL = `${API_BASE_URL}/api/v1/users/me/friends`;
    const response = await fetch(API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.value}`,
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    // console.log('friends response', result);
    // console.log("result==>", result);
    // const userData = {
    //   id: result.data.id,
    //   email: result.data.email,
    //   firstName: result.data.first_name,
    //   lastName: result.data.last_name,
    // };
    // localStorage.setItem("id", userData.id);
    dispatch({
      type: GET_FRIENDS,
      payload: result,
    });
  };
