import React, { useEffect } from "react";
import {
  BsChevronLeft,
  BsFillSquareFill,
  BsGeoAlt,
  BsPin,
  BsPlusLg,
} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ChatForm from "./ChatForm";
import Messages from "./Messages";
import Toolbox from "./Toolbox";

function ChatInbox() {
  const navigate = useNavigate();
  const [active, setActive] = React.useState(0);
  const [selected, setSelected] = React.useState(0);

  useEffect(() => {
    console.log("active====>", active);
    console.log("selected====>", selected);
  }, [active, setSelected]);

  return (
    <div className="relative h-screen chat-bg bg-[url('https://i.pinimg.com/736x/3d/8c/2f/3d8c2f2c82c1c9ef1e27be645cd1aa17.jpg')]">
      <div className="pt-10 pb-2 flex justify-between border-b border-gray-200 bg-gray-100 h-[10%]">
        <button
          className="text-blue-500 flex items-center"
          onClick={() => navigate("/chats")}
        >
          <BsChevronLeft className="text-3xl pb-1" />
          <span className="text-lg">Back</span>
        </button>
        <div className="text-center">
          <div className="text-xl">LBS MBA2023</div>
          <div className="text-sm text-gray-400">85 members, 16 online</div>
        </div>
        <img
          src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
          className="rounded-full w-10 h-10 mr-6 mt-1 sm:mr-6"
        />
      </div>

      <div className="py-2 px-4 relative mx-auto flex justify-between bg-gray-100 h-[6%]">
        <div className="grid">
          <span className="text-blue-600">Pinned Message</span>
          <span>https://www.amazon.co.uk/Texas-Instrument...</span>
        </div>
        <div className="my-auto">
          <BsPin className="text-xl text-blue-600" />
        </div>
      </div>
      <Messages setActive={setActive} selected={selected} />
      {active == 0 ? (
        <ChatForm />
      ) : (
        <Toolbox setActive={setActive} setSelected={setSelected} />
      )}
    </div>
  );
}
export default ChatInbox;
