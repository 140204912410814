import { useEffect, useState } from 'react';
import { RiEditBoxLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../store';
import { ChatState } from '../../reducers/ChatReducer';
import { getChannel } from '../../actions/chatAction';
import NewMessage from './components/new-message-component';
import BottomDrawer from '../../components/Drawer/bottom-drawer';
import NewGroup from './components/new-group-component';
import NewGroupDetail from './components/new-group-detail-component';
import { FriendState } from '../../reducers/FriendReducer';
import { getFriends } from '../../actions/friendAction';

function Chats() {
  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();

  const chat = useSelector<RootState, ChatState>(
    (state: RootState) => state.chatData
  );
  const friends = useSelector<RootState, FriendState>(
    (state: RootState) => state.friendData
  );

  const [newMessage, showNewMessage] = useState(false);
  const [newGroup, showNewGroup] = useState(false);
  const [newGroupDetail, showNewGroupDetail] = useState(false);
  const [year, setYear] = useState([]);
  const [checkedUser, setCheckedUser] = useState([]);

  useEffect(() => {
    dispatch(getChannel());
    dispatch(getFriends());
  }, []);

  useEffect(() => {
    // console.log("checkedUsers", checkedUser);
  }, [checkedUser]);

  const openChat = () => {
    navigate('/chatInbox');
  };

  return (
    <div className='bg-gray-100 relative'>
      <BottomDrawer openDrawer={newMessage} setOpenDrawer={showNewMessage}>
        <NewMessage
          showNewMessage={showNewMessage}
          showNewGroup={showNewGroup}
        />
      </BottomDrawer>

      <BottomDrawer openDrawer={newGroup} setOpenDrawer={showNewGroup}>
        <NewGroup
          showNewGroup={showNewGroup}
          showNewGroupDetail={showNewGroupDetail}
          setCheckedUser={setCheckedUser}
        />
      </BottomDrawer>

      <BottomDrawer
        openDrawer={newGroupDetail}
        setOpenDrawer={showNewGroupDetail}
      >
        <NewGroupDetail
          showNewGroupDetail={showNewGroupDetail}
          checkedUser={checkedUser}
        />
      </BottomDrawer>

      <div className='pt-10 pb-6 text-center text-2xl'>Chats</div>
      <RiEditBoxLine
        className='text-xl text-blue-500 absolute top-11 right-4'
        onClick={() => {
          showNewMessage(true);
        }}
      />
      <button className='text-blue-500 flex items-center absolute top-11 left-4'>
        <span className='text-lg'>Back</span>
      </button>
      <div className='pt-2 pb-2 relative mx-auto text-gray-600 text-center border-b border-gray-200'>
        <input
          className='w-4/5 border-2 bg-gray-200 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none'
          type='search'
          name='search'
          placeholder='Search'
        />
      </div>
      <div className='bg-white'>
        <div className='ml-2 sm:ml-8 sm:mx-12'>
          <a className='cursor-pointer' onClick={() => openChat()}>
            <div className='flex'>
              <div className='py-2'>
                <img
                  src='https://picsum.photos/200/300'
                  className='rounded-full w-14 h-14 mr-6 sm:mr-6'
                />
              </div>

              <div className='py-2 border-b border-gray-200 w-full pr-2'>
                <div className='grid'>
                  <div className='flex justify-between'>
                    <span className='text-sm sm:text-base'>LBS MBA2023</span>
                    <span className='text-sm text-gray-400'>2:09 PM</span>
                  </div>

                  <span className='text-xs'>Swetha Mavuru</span>
                  <span className='text-xs text-gray-400'>Hi everyone</span>
                </div>
              </div>
            </div>
          </a>
          {/* {chat.channelList && chat.channelList.list.length > 0
            ? chat.channelList.list.map((item: any) => {
                return (
                  <a className='flex cursor-pointer' key={item.id}>
                    <div className='py-2'>
                      <img
                        src='https://picsum.photos/200/300'
                        className='rounded-full w-14 h-14 mr-6 sm:mr-6'
                      />
                    </div>
                    <div className='py-2 border-b border-gray-200 w-full pr-2'>
                      <div className='grid'>
                        <div className='flex justify-between'>
                          <span className='text-sm sm:text-base'>
                            {item.name}
                          </span>
                          <span className='text-sm text-gray-400'>2:04 PM</span>
                        </div>
                        <span className='text-xs'>Swetha Mavuru</span>
                        <span className='text-xs text-gray-400'>
                          {item.description}
                        </span>
                      </div>
                    </div>
                  </a>
                );
              })
            : null} */}
        </div>
      </div>
    </div>
  );
}
export default Chats;
