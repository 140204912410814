import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { AuthState } from "../reducers/AuthReducer";
import { Route, Routes, useNavigate } from "react-router-dom";
import Profile from "./Profile/Profile";
import Group from "./Group/Group";
import Contacts from "./Contacts/Index";
import Chats from "./Chats/Chats";
import SavedLists from "./Contacts/SavedLists";
import Footer from "../layouts/Footer";
import OtherProfile from "./Profile/OtherProfile";
import OtherGroup from "./Group/OtherGroup";
import ChatInbox from "./Chats/ChatInbox";
import GroupProfile from "./Profile/Group/group-profile";
const HomeScreen = () => {
  let navigate = useNavigate();

  const userAuth = useSelector<RootState, AuthState>((state) => state.userAuth);

  useEffect(() => {
    console.log("userAuth====>", userAuth);
    if (!userAuth.userInfo) navigate("/login");
    // navigate("/contacts");
  }, [userAuth, navigate]);

  return (
    <>
      <main>
        <Routes>
          {/* <Route path="/detail" element={<DetailScreen />} /> */}
          <Route path="/*" element={<Contacts />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/group" element={<Group />} />
          <Route path="/chats" element={<Chats />} />
          <Route path="/savedlists" element={<Contacts />} />
          <Route path="/otherProfile" element={<OtherProfile />} />
          <Route path="/otherGroup" element={<OtherGroup />} />
          <Route path="/chatInbox" element={<ChatInbox />} />
          <Route path="/group-profile" element={<GroupProfile />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
};

export default HomeScreen;
