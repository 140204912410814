import { useState } from "react";
import { BsFillSquareFill, BsGeoAlt, BsPlusLg, BsSearch } from "react-icons/bs";
import Contacts from "./Contacts";
import SavedLists from "./SavedLists";
import RightDrawer from "../../components/Drawer/right-drawer";
import DrawerComponent from "./drawer-component";

function Index() {
  const path = window.location.href.split("/")[3];
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div className="bg-gray-100 relative">
      <RightDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        <DrawerComponent setOpenDrawer={setOpenDrawer} />
      </RightDrawer>
      <div className="pt-10 pb-6 text-center text-2xl">Contacts</div>
      <BsPlusLg className="text-xl text-blue-500 absolute top-11 right-4" />

      <div className="pt-2 pb-2 relative mx-auto text-gray-600 text-center border-b border-gray-200">
        <input
          className="w-4/5 border-2 bg-gray-200 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          type="search"
          name="search"
          placeholder="Search"
        />
      </div>
      <div className="bg-white">
        <div className="ml-8 sm:mx-12">
          <button className="flex text-blue-500 pl-2 w-full">
            <BsGeoAlt className="text-xl mr-2 my-3" />
            <div className="py-3 border-gray-200 border-b w-full text-left">
              <span>Find People Nearby</span>
            </div>
          </button>
          <button
            className="flex text-blue-500 pl-2 w-full"
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            <BsFillSquareFill className="text-xl mr-2 my-3" />
            <div className="py-3 border-gray-200 border-b w-full text-left">
              <span>Search University Profiles</span>
            </div>
          </button>
        </div>
        {path === "contacts" ? <Contacts /> : <SavedLists />}
      </div>
    </div>
  );
}

export default Index;
