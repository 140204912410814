import { GET_FRIENDS } from '../constants/friendConstants';

export interface FriendState {
  friendList: {
    id?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    profile_image?: string;
    description?: string;
  };
}

interface Action {
  type: string;
  payload?: string;
}

export const FriendReducer = (
  state: FriendState = {
    friendList: {},
  },
  action: Action
) => {
  switch (action.type) {
    case GET_FRIENDS:
      return { friendList: action.payload };
    default:
      return state;
  }
};
