import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

export default function SideBar({ openDrawer, setOpenDrawer, children }: any) {
  const toggleDrawer = (open: any) => (event: any) => {
    // if (
    //   event.type === "keydown" &&
    //   (event.key === "Tab" || event.key === "Shift")
    // ) {
    //   return;
    // }

    if (event.type === "click") {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <Drawer
      anchor={"right"}
      open={openDrawer}
      onClose={toggleDrawer(false)}
      ModalProps={{
        onBackdropClick: () => {
          setOpenDrawer(false);
        },
      }}
      style={{ borderRadius: 20 }}
    >
      <Box
        style={{
          width: 450,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ff9d87",
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
      >
        {children}
      </Box>
    </Drawer>
  );
}
