import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FiRefreshCw } from "react-icons/fi";
import { MdClass, MdOutlineScience } from "react-icons/md";
import { FaUniversity, FaIndustry } from "react-icons/fa";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoGameControllerOutline, IoKeypadOutline } from "react-icons/io5";
import { GoLocation } from "react-icons/go";
import NewGroupDetail from "../../components/Modals/NewGroupDetail";
import TopDrawer from "../../components/Drawer/top-drawer";
import BottomDrawer from "../../components/Drawer/bottom-drawer";
import SearchResultComponent from "./search-result-component";
import SetComponent from "./Components/set-component";
// import SetUniversityComponent from "./Components/set-university-component";

const classList = [
  { label: "2021", value: 1 },
  { label: "2022", value: 2 },
  { label: "2023", value: 3 },
  { label: "2024", value: 4 },
];

const universityList = [
  { label: "Harvard University", value: 1 },
  { label: "Oxford University", value: 2 },
];

const programList = [
  { label: "BA", value: 1 },
  { label: "MD", value: 2 },
  { label: "PhD", value: 3 },
  { label: "MBA", value: 4 },
  { label: "Admin", value: 5 },
  { label: "Prof", value: 6 },
];

const concernList = [
  { label: "Computer Science", value: 1 },
  { label: "Neuroscience", value: 2 },
];

const pastIndustryList = [
  { label: "Consulting", value: 1 },
  { label: "Tech", value: 2 },
  { label: "Google", value: 3 },
];

const futureIndustryList = [{ label: "Entrepreneurship", value: 1 }];

export default function DrawerComponent({ setOpenDrawer }: any) {
  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchResult, setSearchResult] = useState(false);

  const [showYear, setShowYear] = useState(false);
  const [year, setYear] = useState([]);

  const [showUniversity, setShowUniversity] = useState(false);
  const [university, setUniversity] = useState([]);

  const [showProgram, setShowProgram] = useState(false);
  const [program, setProgram] = useState([]);

  const [showConcern, setShowConcern] = useState(false);
  const [concern, setConcern] = useState([]);

  const [showPastIndustry, setShowPastIndustry] = useState(false);
  const [pastIndustry, setPastIndustry] = useState([]);

  const [showFutureIndustry, setShowFutureIndustry] = useState(false);
  const [futureIndustry, setFutureIndustry] = useState([]);

  const setClass = () => {
    setShowYear(true);
  };
  const addUniversity = () => {
    setShowUniversity(true);
  };
  const addProgram = () => {
    setShowProgram(true);
  };
  const addConcern = () => {
    setShowConcern(true);
  };
  const addIndustry = () => {
    setShowPastIndustry(true);
  };
  const addFurtureIndustry = () => {
    setShowFutureIndustry(true);
  };

  useEffect(() => {
    console.log("setYear", year.length);
  }, [year, setYear]);

  useEffect(() => {
    console.log("setUniversity", university);
  }, [university, setUniversity]);

  return (
    <div>
      <TopDrawer openDrawer={searchResult} setOpenDrawer={setSearchResult}>
        <SearchResultComponent setOpenDrawer={setSearchResult} />
      </TopDrawer>
      {/* Year */}
      <BottomDrawer openDrawer={showYear} setOpenDrawer={setShowYear}>
        <SetComponent
          setValue={setYear}
          setOpenDrawer={setShowYear}
          list={classList}
        />
      </BottomDrawer>
      {/* University */}
      <BottomDrawer
        openDrawer={showUniversity}
        setOpenDrawer={setShowUniversity}
      >
        <SetComponent
          setValue={setUniversity}
          setOpenDrawer={setShowUniversity}
          list={universityList}
        />
      </BottomDrawer>
      {/* Program */}
      <BottomDrawer openDrawer={showProgram} setOpenDrawer={setShowProgram}>
        <SetComponent
          setValue={setProgram}
          setOpenDrawer={setShowProgram}
          list={programList}
        />
      </BottomDrawer>
      {/* Concern */}
      <BottomDrawer openDrawer={showConcern} setOpenDrawer={setShowConcern}>
        <SetComponent
          setValue={setConcern}
          setOpenDrawer={setShowConcern}
          list={concernList}
        />
      </BottomDrawer>
      {/* Past Industry */}
      <BottomDrawer
        openDrawer={showPastIndustry}
        setOpenDrawer={setShowPastIndustry}
      >
        <SetComponent
          setValue={setPastIndustry}
          setOpenDrawer={setShowPastIndustry}
          list={pastIndustryList}
        />
      </BottomDrawer>
      {/* Furture Industry */}
      <BottomDrawer
        openDrawer={showFutureIndustry}
        setOpenDrawer={setShowFutureIndustry}
      >
        <SetComponent
          setValue={setFutureIndustry}
          setOpenDrawer={setShowFutureIndustry}
          list={futureIndustryList}
        />
      </BottomDrawer>

      <div className="pt-14 px-8 flex justify-between bg-[#fba491]">
        <button
          className="flex"
          onClick={() => {
            setSearchResult(true);
          }}
        >
          <AiOutlineUnorderedList className="mt-[3px] text-white" />
          <span className="pl-2 text-base text-white">Create list</span>
        </button>

        <button className="flex" onClick={() => setShowModal(true)}>
          <FiRefreshCw className="mt-[3px] text-white" />
          <span className="pl-2 text-base text-white">
            Create Group From List
          </span>
        </button>
      </div>
      <div className="px-8">
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button
            className="flex text-white"
            onClick={() => {
              setClass();
            }}
          >
            <MdClass className="mt-[6px] " />
            <span className="pl-2 text-xl">Class of...</span>
          </button>
          <div className="w-[180px] text-sm">
            {year.length !== 0
              ? year.map((m: any) => {
                  return (
                    <span
                      className="text-white p-1 bg-blue-500 rounded-lg m-0.5"
                      key={m.value}
                    >
                      {m.label}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button
            className="flex text-white"
            onClick={() => {
              addUniversity();
            }}
          >
            <FaUniversity className="mt-[6px]" />
            <span className="pl-2 text-xl">University</span>
          </button>
          <div className="w-[180px] text-sm flex">
            {university.length !== 0
              ? university.map((m: any) => {
                  return (
                    <span
                      className="text-white p-1 bg-blue-500 rounded-lg m-0.5"
                      key={m.value}
                    >
                      {m.label}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button
            className="flex text-white"
            onClick={() => {
              addProgram();
            }}
          >
            <FaUniversity className="mt-[6px] " />
            <span className="pl-2 text-xl">University Program</span>
          </button>
          <div className="w-[180px] text-sm flex">
            {program.length !== 0
              ? program.map((m: any) => {
                  return (
                    <span
                      className="text-white p-1 bg-blue-500 rounded-lg m-0.5"
                      key={m.value}
                    >
                      {m.label}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button
            className="flex text-white"
            onClick={() => {
              addConcern();
            }}
          >
            <MdOutlineScience className="mt-[6px]" />
            <span className="pl-2 text-xl">Major/Concentration</span>
          </button>
          <div className="w-[180px] text-sm flex">
            {concern.length !== 0
              ? concern.map((m: any) => {
                  return (
                    <span
                      className="text-white p-1 bg-blue-500 rounded-lg m-0.5"
                      key={m.value}
                    >
                      {m.label}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button
            className="flex text-white"
            onClick={() => {
              addIndustry();
            }}
          >
            <FaIndustry className="mt-[6px] " />
            <div className="pl-2 text-xl grid">
              <span className="text-left">Past/Current</span>
              <span className="text-left">Company or Industry</span>
            </div>
          </button>
          <div className="w-[180px] text-sm">
            {pastIndustry.length !== 0
              ? pastIndustry.map((m: any) => {
                  return (
                    <span
                      className="text-white p-1 bg-blue-500 rounded-lg m-0.5"
                      key={m.value}
                    >
                      {m.label}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button
            className="flex text-white"
            onClick={() => {
              addFurtureIndustry();
            }}
          >
            <FaIndustry className="mt-[6px] " />
            <span className="pl-2 text-xl text-left">
              Desired or Future Industry
            </span>
          </button>
          <div className="w-[180px] text-sm">
            {futureIndustry.length !== 0
              ? futureIndustry.map((m: any) => {
                  return (
                    <span
                      className="text-white p-1 bg-blue-500 rounded-lg m-0.5"
                      key={m.value}
                    >
                      {m.label}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button className="flex text-white ">
            <HiOutlineOfficeBuilding className="mt-[6px] " />
            <span className="pl-2 text-xl">Clubs And Societies</span>
          </button>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button className="flex text-white ">
            <IoGameControllerOutline className="mt-[6px] " />
            <span className="pl-2 text-xl">Interests</span>
          </button>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button className="flex text-white ">
            <IoKeypadOutline className="mt-[6px] " />
            <span className="pl-2 text-xl">Keywords</span>
          </button>
        </div>
        <div className="flex justify-between py-4 border-b border-gray-200">
          <button className="flex text-white ">
            <GoLocation className="mt-[6px] " />
            <span className="pl-2 text-xl">Location</span>
          </button>
        </div>
      </div>
      {showModal == true ? (
        <NewGroupDetail setShowModal={setShowModal} />
      ) : null}
    </div>
  );
}
