import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import {
  BsCheckCircle,
  BsForward,
  BsLink45Deg,
  BsPin,
  BsReply,
} from 'react-icons/bs';
import { FiCopy, FiAlertCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { ChatState } from '../../reducers/ChatReducer';
import { getMessages } from '../../actions/chatAction';
import ForwardModal from '../../components/Modals/ForwardModal';
import BottomDrawer from '../../components/Drawer/bottom-drawer';
import ForwardComponent from './forward-component';

const messageData = [
  {
    id: 'msg_9daea7c27e',
    sender_id: 'usr_faf1bcd065',
    reply_message_id: 'msg_f795d9eda2',
    message:
      'Anybody on here who was supposed to attend the BCG event right now?',
    time: '2022-06-02 18:35:11',
    files: [],
  },
  {
    id: 'msg_0ffa3f7b1f',
    sender_id: 'usr_faf1bcd065',
    reply_message_id: null,
    message: "Yes. It's cancelled",
    time: '2022-06-02 18:26:52',
    files: [],
  },
  {
    id: 'msg_eaba06397b',
    sender_id: 'usr_faf1bcd065',
    reply_message_id: null,
    message:
      "Just want to make sure it was actually cancelled cos I haven't heard anything but don't have a link either",
    time: '2022-06-02 18:07:33',
    files: [
      {
        url: 'http://127.0.0.1:8000/uploads/files/messages/16541932538.jpg',
        file_type: 1,
      },
      {
        url: 'http://127.0.0.1:8000/uploads/files/messages/1654193253560.jpg',
        file_type: 1,
      },
    ],
  },
  {
    id: 'msg_f9b85107f3',
    sender_id: 'usr_63dfb119a4',
    reply_message_id: null,
    message:
      'Anybody on here who was supposed to attend the BCG event right now?',
    time: '2022-06-02 17:31:01',
    files: [
      {
        url: 'http://127.0.0.1:8000/files/messages/1654191061210.jpg',
        file_type: 1,
      },
    ],
  },
  {
    id: 'msg_aa89a76801',
    sender_id: 'usr_faf1bcd065',
    reply_message_id: null,
    message: 'Plan is to meet at Baker Street at 13:30',
    time: '2022-06-02 17:27:04',
    files: [
      {
        url: 'http://127.0.0.1:8000/public/files/messages1654190824336.jpg',
        file_type: 1,
      },
    ],
  },
];

function Messages({ setActive, selected }: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const [showModal, setShowModal] = useState(false);
  // const [forwardModal, setForwardModal] = useState(false);
  const [messageId, setMessageId] = useState('');
  const [selectedMsg, setSelectedMsg] = useState<{ id: string }[]>([]);

  const [showForwardChat, setShowForwardChat] = useState(false);

  const navigate = useNavigate();
  const userId = localStorage.getItem('id');
  const messages = useSelector<RootState, ChatState>(
    (state: RootState) => state.chatData
  );

  useEffect(() => {
    dispatch(getMessages());
    console.log('message', messages);
  }, []);

  useEffect(() => {
    console.log('selectedMsg', selectedMsg);
    console.log('selected123', selected);
  }, [setSelectedMsg]);

  const selectMsg = () => {
    setSelectedMsg([{ id: messageId }]);
    setActive(1);
    setShowModal(false);
  };

  const clickMsg = (id: any) => {
    setShowModal(true);
    setMessageId(id);
  };

  const Forward = () => {
    setShowModal(false);
    // setForwardModal(true);
    setShowForwardChat(true);
  };

  const MsgElement = messageData?.map((msg) => {
    return (
      <div key={msg.id}>
        {msg.sender_id == userId ? (
          <div
            className={
              selectedMsg.length == 0 || selected == 1
                ? ``
                : `flex justify-between`
            }
          >
            {selectedMsg.length == 0 || selected == 1 ? (
              <></>
            ) : (
              <input
                className='w-6 h-6 rounded-md'
                value=''
                style={{ margin: '20px' }}
                type='checkbox'
              />
            )}
            <a
              className='flex px-2 justify-end'
              onClick={() => {
                clickMsg(msg.id);
              }}
            >
              <div className='w-fit bg-white rounded-[20px] inline-block p-[10px] text-gray-900 font-light font-sm m-[5px] relative float-left clear-both rounded-br-[0px]'>
                <div className='max-w-sm'>
                  {msg.message}
                  <span className='absolute right-3 bottom-0 text-xs text-gray-400'>
                    5:13 PM
                  </span>
                </div>
              </div>
              <img
                src='https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ'
                className='rounded-full w-10 h-10 mr-2 mt-2 sm:mr-2'
              />
            </a>
          </div>
        ) : (
          <div className='flex justify-start'>
            {selectedMsg.length == 0 || selected == 1 ? (
              <></>
            ) : (
              <input
                className='w-6 h-6 rounded-md'
                value=''
                style={{ margin: '20px' }}
                type='checkbox'
              />
            )}
            <a
              className='flex px-2'
              onClick={() => {
                clickMsg(msg.id);
              }}
            >
              <img
                src='https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ'
                className='rounded-full w-10 h-10 mr-2 mt-2 sm:mr-2'
              />
              <div className='w-fit bg-white rounded-[20px] inline-block p-[10px] text-gray-900 font-light font-sm m-[5px] relative float-left clear-both rounded-bl-[0px]'>
                <div className='max-w-sm'>
                  {msg.message}
                  <span className='absolute right-3 bottom-0 text-xs text-gray-400'>
                    5:13 PM
                  </span>
                </div>
              </div>
            </a>
          </div>
        )}
      </div>
    );
  });

  return (
    <div className='h-[74%] flex-col-reverse overflow-y-scroll flex'>
      <BottomDrawer
        openDrawer={showForwardChat}
        setOpenDrawer={setShowForwardChat}
        height='90'
      >
        <ForwardComponent setOpenDrawer={setShowForwardChat} />
      </BottomDrawer>

      {MsgElement}
      {showModal ? (
        <div className='max-w-xl m-auto justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
          <div className='bg-opacity-30 w-full h-full absolute'></div>
          <div className="flex-col-reverse overflow-y-scroll flex relative w-full h-full border-2 rounded-lg bg-cover bg-[url('https://images.prismic.io/exclusiveresorts/b90b79d1-a36a-456c-8ce6-32570c2f0033_er-kapalua-bay-2508_21_q8ugrz-Vertical_Slider_Tablet_1200x740.jpg?auto=compress,format&w=512&q=40&blur=500')]">
            <div className='mx-auto pb-6'>
              <div className='w-fit bg-white rounded-[20px] inline-block text-gray-900 font-light font-sm m-[5px] relative float-left clear-both rounded-xs-[0px]'>
                <div className='max-w-[280px] grid'>
                  <a className='border-b border-gray-200 py-[10px] px-[16px] flex justify-between space-x-44'>
                    <span>Reply</span>
                    <BsReply className='text-xl' />
                  </a>
                  <a className='border-b border-gray-200 py-[10px] px-[16px] flex justify-between '>
                    <span>Copy</span>
                    <FiCopy className='text-xl' />
                  </a>
                  <a className='border-b border-gray-200 py-[10px] px-[16px] flex justify-between '>
                    <span>Pin</span>
                    <BsPin className='text-xl' />
                  </a>
                  <a className='border-b border-gray-200 py-[10px] px-[16px] flex justify-between '>
                    <span>Copy Link</span>
                    <BsLink45Deg className='text-xl' />
                  </a>
                  <a
                    className='border-b border-gray-200 py-[10px] px-[16px] flex justify-between'
                    onClick={() => {
                      Forward();
                    }}
                  >
                    <span>Forward</span>
                    <BsForward className='text-xl' />
                  </a>
                  <a className='py-[10px] px-[16px] flex justify-between '>
                    <span>Report</span>
                    <FiAlertCircle className='text-xl' />
                  </a>
                  <div className='h-2 bg-gray-200'></div>
                  <a
                    className='py-[10px] px-[16px] flex justify-between'
                    onClick={() => {
                      selectMsg();
                    }}
                  >
                    <span>Select</span>
                    <BsCheckCircle className='text-xl' />
                  </a>
                </div>
              </div>
            </div>

            <div className='mx-auto pb-2'>
              <div className='w-fit bg-white rounded-[20px] inline-block p-[10px] text-gray-900 font-light font-sm m-[5px] relative float-left clear-both rounded-bl-[0px]'>
                <div className='max-w-sm'>
                  Anybody on here who was supposed to attend the BCG event right
                  now?
                  <span className='absolute right-3 bottom-0 text-xs text-gray-400'>
                    5:13 PM
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default Messages;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
