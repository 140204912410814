import { BsChevronRight, BsFillSquareFill } from "react-icons/bs";
import { IoMdNotificationsOutline } from "react-icons/io";
import {
  IoClose,
  IoLocationOutline,
  IoPersonAddOutline,
  IoTimeOutline,
  IoVideocamOutline,
} from "react-icons/io5";
import Switchs from "../../../components/Switch";
const ForwardCalendarComponent = ({ setOpenDrawer }: any) => {
  return (
    <div>
      <div className="pt-5 pb-2 bg-gray-100 h-[10%] relative">
        <div className="flex justify-between">
          <button
            className="pl-4 flex items-center"
            onClick={() => {
              setOpenDrawer(false);
            }}
          >
            <IoClose className="text-2xl" />
          </button>
          <button className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs mr-2 px-5 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Save
          </button>
        </div>
      </div>
      <div className="pt-2 pb-2 relative mx-auto text-gray-600 text-center border-b border-gray-200 bg-gray-100">
        <span className="text-2xl">Add Title</span>
      </div>

      <div className="px-10 py-2 flex justify-between">
        <div className="flex">
          <div className="py-2">
            <IoTimeOutline className="text-xl mr-4" />
          </div>
          <div className="text-lg my-auto">All-day</div>
        </div>
        <div className="my-auto">
          <Switchs title="" subtitle="" className="grid pl-4 text-gray-400" />
        </div>
      </div>
      <div className="px-10 py-2 flex justify-between">
        <div className="text-lg my-auto pl-[35px]">Friday, Jan 21</div>
        <div className="my-auto">2 PM</div>
      </div>
      <div className="px-10 py-2 flex justify-between">
        <div className="text-lg my-auto pl-[35px]">Friday, Jan 21</div>
        <div className="my-auto">3 PM</div>
      </div>
      <div className="px-10 py-2 flex justify-between border-b border-gray-200">
        <div className="text-lg my-auto pl-[35px] text-gray-500">
          More Options
        </div>
      </div>
      <div className="px-10 py-2 flex justify-between border-b border-gray-200">
        <div className="flex">
          <div className="py-2">
            <IoPersonAddOutline className="text-xl mr-4" />
          </div>
          <div className="text-lg my-auto">Add People</div>
        </div>
      </div>
      <div className="px-10 py-2 flex justify-between border-b border-gray-200">
        <div className="flex">
          <div className="py-2">
            <IoVideocamOutline className="text-xl mr-4" />
          </div>
          <div className="text-lg my-auto">Add Video Conferencing</div>
        </div>
      </div>
      <div className="px-10 py-2 flex justify-between border-b border-gray-200">
        <div className="flex">
          <div className="py-2">
            <IoLocationOutline className="text-xl mr-4" />
          </div>
          <div className="text-lg my-auto">Add Location</div>
        </div>
      </div>
      <div className="px-10 py-2 flex justify-between">
        <div className="flex">
          <div className="py-2">
            <IoMdNotificationsOutline className="text-xl mr-4" />
          </div>
          <div className="text-lg my-auto">30 minutes before</div>
        </div>
      </div>
      <div className="px-10 py-2 flex justify-between border-b border-gray-200">
        <div className="text-lg my-auto pl-[35px] text-gray-500">
          Add another notification
        </div>
      </div>
      <div className="px-10 py-2 flex justify-between">
        <div className="flex">
          <div className="py-2">
            <BsFillSquareFill className="text-xl mr-4 text-blue-600" />
          </div>
          <div className="text-lg my-auto">Default color</div>
        </div>
        <div className="my-auto">
          <BsChevronRight className="text-xl" />
        </div>
      </div>
    </div>
  );
};

export default ForwardCalendarComponent;
