const user = [
  {
    id: "usr_06fcc6632e",
    first_name: "Jeff",
    last_name: "Schowalter",
    email: "doyle.maxine@example.com",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+ex",
    description: "Aliquam eos rerum ipsa qui molestiae esse.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
  },
  {
    id: "usr_e551f9f1c9",
    first_name: "Arianna",
    last_name: "Langosh",
    email: "goldner.emmanuel@example.net",
    profile_image:
      "https://via.placeholder.com/640x480.png/0044ee?text=animals+hic",
    description: "Natus quia laudantium odit qui facere qui.",
    created_at: "2022-06-08T17:20:10.000000Z",
    updated_at: "2022-06-08T17:20:10.000000Z",
  },
];

const ForwardChatComponent = ({ setOpenDrawer }: any) => {
  const UserList = user.map((m) => {
    return (
      <div className="flex " key={m.id}>
        <div className="py-2">
          <img
            src={m.profile_image}
            className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
          />
        </div>
        <div className="py-2 border-b border-gray-200 w-full my-auto">
          <div className="text-sm sm:text-base">
            {m.first_name} {m.last_name}{" "}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <div className="pt-10 pb-2 bg-gray-100 h-[10%] relative">
        <button
          className="absolute pl-6 text-blue-500 flex items-center"
          onClick={() => {
            setOpenDrawer(false);
          }}
        >
          <span className="text-lg">Cancel</span>
        </button>
        <div className="text-center">
          <div className="text-xl font-bold">Forward</div>
        </div>
        <button className="absolute right-[20px] top-[40px] text-gray-500 flex items-center">
          <span className="text-lg">Select</span>
        </button>
      </div>
      <div className="pt-2 pb-2 relative mx-auto text-gray-600 text-center border-b border-gray-200 bg-gray-100">
        <input
          className="w-4/5 border-2 bg-gray-200 h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          type="search"
          name="search"
          placeholder="Search"
        />
      </div>

      <div className="ml-2 sm:ml-4">
        <div className="flex">
          <div className="py-2">
            <img
              src="https://thumbs.dreamstime.com/b/blue-bookmark-icon-vector-illustration-94347253.jpg"
              className="rounded-full w-8 h-8 sm:w-10 sm:h-10 mr-2 sm:mr-4"
            />
          </div>
          <div className="py-2 border-b border-gray-200 w-full my-auto">
            <div className="text-sm sm:text-base">Saved Messages</div>
          </div>
        </div>
        {UserList}
      </div>
    </div>
  );
};

export default ForwardChatComponent;
