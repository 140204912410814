import {
  ADD_CHANNEL,
  GET_CHANNEL,
  GET_MESSAGE,
  LEAVE_CHANNEL,
} from '../constants/chatConstants';

export interface ChatState {
  msgData: any[];
  channelList: {
    list?: any;
  };
  addChannel: {
    data?: string;
    success?: boolean;
    message?: string;
  };
  leaveChannel: {
    data?: string;
    success?: boolean;
    message?: string;
  };
}

interface Action {
  type: string;
  payload?: string;
}

export const ChatReducer = (
  state: ChatState = {
    msgData: [],
    channelList: {},
    addChannel: {},
    leaveChannel: {},
  },
  action: Action
) => {
  switch (action.type) {
    case GET_MESSAGE:
      return { msgData: action.payload };
    case GET_CHANNEL:
      return { ...state, channelList: action.payload };
    case ADD_CHANNEL:
      return { ...state, addChannel: action.payload };
    case LEAVE_CHANNEL:
      return { leaveChannel: action.payload };
    default:
      return state;
  }
};
