import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  BsFillTelephoneFill,
  BsCameraVideoFill,
  BsFolder2Open,
  BsChevronLeft,
  BsGear,
} from "react-icons/bs";
import TabsRender from "./ProfileTab";
import { RootState } from "../../store";
import { getUserDetail } from "../../actions/profileAction";
import { ProfileState } from "../../reducers/profileReducer";
import Menu from "../../components/Menu";
import { useLocation, useNavigate } from "react-router-dom";

function OtherProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: Dispatch<any> = useDispatch();
  const [openMessage, setOpenMessage] = React.useState(0);
  const [userId, setUserId] = React.useState(0);
  const [currentId, setCurrentId] = React.useState(1);
  const [userData, setUserData] = React.useState(null);

  const user = useSelector<RootState, ProfileState>(
    (state: RootState) => state.userData
  );

  useEffect(() => {
    Object.entries(location).map(([key, value]) => {
      if (key == "state") {
        setUserId(value[0].id);
        setUserData(value);
        setCurrentId(currentId);
        console.log("value", value);
      }
    });
  }, []);

  useEffect(() => {
    // dispatch(getOtherUser(openTab));
  }, []);

  useEffect(() => {
    console.log("currentId", currentId);
  }, [currentId]);

  const InviteGroup = () => {
    setCurrentId(currentId + 1);
    setUserId(currentId + 1);
  };

  const goBack = () => {
    navigate("/savedlists");
  };

  const viewGroup = () => {
    navigate("/otherGroup", { state: currentId });
  };

  return (
    <div>
      <div className="bg-gray-100">
        <div className="flex items-center justify-center">
          <div className="mt-10 rounded-lg">
            <div className="flex justify-between">
              <button className="text-blue-500 flex" onClick={goBack}>
                <BsChevronLeft className="text-3xl pb-1" />
                <span className="text-lg">Back</span>
              </button>
              <div className="text-blue-500">
                <BsGear className="text-3xl m-auto" />
                <span className="text-xs">Settings</span>
              </div>
            </div>
            <div className="flex items-center justify-center flex-col">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyuNFyw05KSucqjifL3PhDFrZLQh7QAS-DTw&usqp=CAU"
                className="rounded-full w-32 h-32"
              />
              <h1 className="text-gray-800 font-semibold text-3xl mt-5">
                Person ({userId})
              </h1>
              <h1 className="text-gray-400 text-sm">
                last seen today at 5:51 PM
              </h1>
            </div>

            <div className="flex items-center justify-center mt-3 mb-2 flex-col ">
              <div className="flex mt-2">
                <div className="p-2 bg-white rounded-lg w-96 flex center">
                  <button className="bg-blue-500 rounded-lg p-2 m-2 w-80">
                    <span className="box-border text-center font-bold text-base text-white">
                      Invite to Connect
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center m1-3 mb-2 flex-col ">
              <div className="flex mt-2">
                <div className="p-2 bg-white rounded-lg w-96 flex justify-around">
                  <button
                    className="bg-slate-400 rounded-lg p-2 m-2 w-44"
                    onClick={viewGroup}
                  >
                    <span className="box-border text-center font-bold text-base text-white">
                      View Shared Groups
                    </span>
                  </button>
                  <div className="grid">
                    <span className="text-xs font-bold">school program</span>
                    <span className="text-lg text-center">MBA 2023</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="p-2 bg-white rounded-lg mr-1 sm:mr-2">
                <button className="bg-red-400 rounded-lg p-1">
                  <div className="box-border h-10 w-14 sm:w-20 text-center font-medium text-xs text-white pt-1">
                    <span>Remove from List</span>
                  </div>
                </button>
              </div>
              <div className="p-2 bg-white rounded-lg mr-1 sm:mr-2">
                <button className="bg-green-500 rounded-lg p-1">
                  <div className="box-border h-10 w-14 sm:w-20 text-center font-medium text-xs text-white pt-3">
                    <span>Keep in List</span>
                  </div>
                </button>
              </div>
              <div className="p-2 bg-white rounded-lg mr-1 sm:mr-2">
                <button
                  className="bg-purple-500 rounded-lg p-1"
                  onClick={() => {
                    InviteGroup();
                  }}
                >
                  <div className="box-border h-10 w-14 sm:w-20 text-center font-medium text-xs text-white pt-3">
                    <span>Invite to Group</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <TabsRender openMessage={openMessage} userId={userId} />
      </div>
    </div>
  );
}
export default OtherProfile;
