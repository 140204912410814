import {
  ADD_CHANNEL,
  GET_CHANNEL,
  GET_MESSAGE,
  LEAVE_CHANNEL,
  SEND_MESSAGE,
} from '../constants/chatConstants';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../store';

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getMessages =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem('userInfo')!) };
    const channelId = 'chan_653d605d71';
    const API_URL = `${API_BASE_URL}/api/v1/channels/${channelId}/get-messages`;
    const response = await fetch(API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.value}`,
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    // console.log("result==>", result);
    // const userData = {
    //   id: result.data.id,
    //   email: result.data.email,
    //   firstName: result.data.first_name,
    //   lastName: result.data.last_name,
    // };
    // localStorage.setItem("id", userData.id);
    dispatch({
      type: GET_MESSAGE,
      payload: result,
    });
  };

export const getChannel =
  (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    var token = { value: JSON.parse(localStorage.getItem('userInfo')!) };
    const API_URL = `${API_BASE_URL}/api/v1/users/me/channels`;
    const response = await fetch(API_URL, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.value}`,
        Accept: 'application/json',
      },
    });
    const result = await response.json();
    const channelList = {
      list: result.data,
    };
    // localStorage.setItem("id", userData.id);
    dispatch({
      type: GET_CHANNEL,
      payload: channelList,
    });
  };

// == Add Channel == //
export const addChannel =
  (
    name: string,
    description: string,
    users: any
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const SAVE_CLUB_URL = `${API_BASE_URL}/api/v1/users/channels/store`;
      var token = { value: JSON.parse(localStorage.getItem('userInfo')!) };
      const tags = [1, 5];
      const is_closed = false;
      const response = await fetch(SAVE_CLUB_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token.value}`,
        },
        body: JSON.stringify({
          name,
          description,
          tags,
          is_closed,
          users,
        }),
      });

      const res = await response.json();
      console.log('Add Chanel Responsive', res);
      const add_channel_res = {
        data: res.data,
        success: res.success,
        message: res.message,
      };
      dispatch({
        type: ADD_CHANNEL,
        payload: add_channel_res,
      });
    } catch (error) {}
  };

// == Leave Channel == //
export const leaveChannel =
  (
    channelId: string
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const SAVE_CLUB_URL = `${API_BASE_URL}/api/v1/users/channels/${channelId}/leave`;
      var token = { value: JSON.parse(localStorage.getItem('userInfo')!) };
      const response = await fetch(SAVE_CLUB_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token.value}`,
        },
        body: '',
      });

      const res = await response.json();
      console.log('Leave Channel Responsive', res);
      const leave_channel_res = {
        data: res.data,
        success: res.success,
        message: res.message,
      };
      dispatch({
        type: LEAVE_CHANNEL,
        payload: leave_channel_res,
      });
    } catch (error) {}
  };

// == Send Message == //
export const SendMessage =
  (
    channelId: string,
    message: string,
    fileType: number,
    replyMsgId: string
  ): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
  async (
    dispatch: ThunkDispatch<RootState, unknown, AnyAction>
  ): Promise<void> => {
    try {
      const API_URL = `${API_BASE_URL}/api/v1/users/channels/messages/store`;
      var token = { value: JSON.parse(localStorage.getItem('userInfo')!) };
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token.value}`,
        },
        body: JSON.stringify({
          channelId,
          message,
          fileType,
          replyMsgId,
        }),
      });

      const res = await response.json();
      console.log('Send Message Responsive', res);
      const send_message_res = {
        data: res.data,
        success: res.success,
        message: res.message,
      };
      dispatch({
        type: SEND_MESSAGE,
        payload: send_message_res,
      });
    } catch (error) {}
  };
