import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import SignupScreen from "./screens/SignupScreen";
import LoginScreen from "./screens/LoginScreen";
import DetailScreen from "./screens/DetailScreen";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Profile from "./screens/Profile/Profile";
import Group from "./screens/Group/Group";
import Contacts from "./screens/Contacts/Index";
import Chats from "./screens/Chats/Chats";
import SavedLists from "./screens/Contacts/SavedLists";

// console.log(process.env);

function App() {
  return (
    <Router>
      <main className="max-w-xl m-auto">
        {/* <Header /> */}
        <Routes>
          <Route path="/*" element={<HomeScreen />} />
          <Route path="/signup" element={<SignupScreen />} />
          <Route path="/login" element={<LoginScreen />} />
        </Routes>
      </main>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
