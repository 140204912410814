import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

export default function BottomDrawer({
  openDrawer,
  setOpenDrawer,
  children,
  height,
}: any) {
  const toggleDrawer = (open: any) => (event: any) => {
    // if (
    //   event.type === "keydown" &&
    //   (event.key === "Tab" || event.key === "Shift")
    // ) {
    //   return;
    // }

    if (event.type === "click") {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={openDrawer}
      onClose={toggleDrawer(false)}
      ModalProps={{
        onBackdropClick: () => {
          setOpenDrawer(false);
        },
      }}
      PaperProps={{
        sx: {
          width: "100%",
          height: `${height}%`,
          maxWidth: 580,
          marginLeft: "auto",
          marginRight: "auto",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        },
      }}
      style={{ position: "relative" }}
    >
      <Box
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        role="presentation"
        onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
      >
        {children}
      </Box>
    </Drawer>
  );
}
