import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  getClubs,
  getDirections,
  getInterests,
  getNotes,
  saveClub,
  saveDirection,
  saveExperiences,
  saveInterest,
} from "../../actions/profileAction";
import ComboBox from "../Combobox";

type Props = {
  list: any;
  data?: any[];
  text: string;
  className?: string;
  setExperience?: (newType: any) => void;
};

const Modal = ({ list, data, text, className, setExperience }: Props) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  // const [experience, setExperience] = useState<any>(null);

  const [position, setPosition] = useState<any>(null);
  const [club, setClub] = useState<any>(null);
  const [direction, setDirection] = useState<any>(null);
  const [interest, setInterest] = useState<any>(null);
  const [university, setUniversity] = useState<any>(null);
  const [major, setMajor] = useState<any>(null);
  const [graduation, setGraduation] = useState<any>(null);
  const [degree, setDegree] = useState<any>(null);

  useEffect(() => {
    console.log("setSelected===>", selectedItem);
    // setClub(selectedItem?.selected);
    if (selectedItem && selectedItem.type === "position") {
      setPosition(selectedItem);
    } else if (selectedItem?.selected && selectedItem.type === "club") {
      setClub(selectedItem?.selected);
    } else if (selectedItem?.selected && selectedItem.type === "direction") {
      setDirection(selectedItem?.selected);
    } else if (selectedItem?.selected && selectedItem.type === "interest") {
      setInterest(selectedItem?.selected);
    } else if (selectedItem && selectedItem.type === "university") {
      setUniversity(selectedItem);
    } else if (selectedItem && selectedItem.type === "major") {
      setMajor(selectedItem);
    } else if (selectedItem && selectedItem.type === "graduation") {
      setGraduation(selectedItem);
    } else if (selectedItem && selectedItem.type === "degree") {
      setDegree(selectedItem);
    }
  }, [selectedItem, setSelectedItem]);

  const onSubmit = () => {
    setShowModal(false);
    if (club && position) {
      dispatch(saveClub(club.name, position.name));
    } else if (interest) {
      dispatch(saveInterest(interest.name));
    } else if (direction) {
      dispatch(saveDirection(direction.name));
    } else if (university && major && graduation && degree) {
      const educationData = {
        university: university.name,
        major: major.name,
        graduation: graduation.name,
        degree: degree.name,
      };
      console.log(educationData);
      setExperience?.(educationData);
    }
  };

  const ComboboxElement = data?.map((m: any, index: string | number) => {
    return (
      <ComboBox
        list={m}
        // name={list[index]}
        setSelectedItem={setSelectedItem}
        key={list[index]}
        name={""}
      />
    );
  });

  return (
    <>
      <button
        className={className}
        type="button"
        onClick={() => setShowModal(true)}
      >
        {text}
      </button>
      {showModal ? (
        <>
          <div className="max-w-xl m-auto flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full">
              <div className="border-1 rounded-lg shadow-lg relative flex flex-col w-full h-full bg-white outline-none focus:outline-none">
                {ComboboxElement}
                <div className="flex absolute bottom-0 w-full items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-blue-500 font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="text-blue-500 font-bold text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => onSubmit()}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
