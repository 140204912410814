import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Dispatch } from "redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../Input";
import { BsPersonPlus } from "react-icons/bs";
import { addNote } from "../../actions/profileAction";

interface FormData {
  title: string;
  note: string;
  date: string;
}

const ProfileNoteModal = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({ mode: "onChange" });

  const onSubmit = handleSubmit(({ title, note, date }) => {
    dispatch(addNote(title, note, date));
    setShowModal(false);
  });
  return (
    <>
      <button
        className="text-blue-500 active:text-gray-800 outline-none focus:text-gray-800 ml-1 mb-1"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <div className="flex text-blue-500 pl-4 pb-4">
          <BsPersonPlus className="text-3xl mr-4" />
          <span className="text-xl">Add Note</span>
        </div>
      </button>
      {showModal ? (
        <>
          <div className="max-w-xl m-auto flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full h-full bg-white border-2 rounded-lg">
              <div className="max-w-sm w-full mx-auto mt-10 bg-white border-gray-300 px-4 sm:px-0">
                <div className="mb-4 text-2xl text-center">Add Note</div>
                <form action="" className="space-y-6" onSubmit={onSubmit}>
                  <div>
                    <div className="flex items-center border-b border-slate-300 py-2 ">
                      <Input
                        register={register}
                        name="title"
                        type="text"
                        placeholder="Title"
                        ariaLabel="Title"
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
                      />
                    </div>
                    <div className="mt-1 ml-2 text-red-700">
                      {errors.title && "Title is required"}
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center border-b border-slate-300 py-2">
                      <Input
                        register={register}
                        name="note"
                        type="text"
                        placeholder="Note"
                        ariaLabel="Note"
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none"
                      />
                    </div>
                    <div className="mt-1 ml-2 text-red-700">
                      {errors.note && "Note is invalid"}
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center border-b border-slate-300 py-2">
                      <Input
                        register={register}
                        name="date"
                        type="date"
                        placeholder="Date"
                        ariaLabel="Date"
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 px-2 leading-tight focus:outline-none "
                      />
                    </div>
                    <div className="mt-1 ml-2 text-red-700">
                      {errors.date && "Graduation Year is required"}
                    </div>
                  </div>
                </form>
              </div>

              <div className="flex absolute bottom-0 w-full items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-blue-500 font-bold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="text-blue-500 font-bold text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                  type="button"
                  onClick={() => onSubmit()}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ProfileNoteModal;
