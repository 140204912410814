import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export default function SetComponent({ setOpenDrawer, setValue, list }: any) {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    console.log("selectedOption", selectedOption);
  }, [setSelectedOption]);

  const SelectValue = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    // setYear(selectedOption);
  };

  const addValue = () => {
    console.log("selectedOption", selectedOption);
    setValue(selectedOption);
    setOpenDrawer(false);
  };

  return (
    <div>
      <Select
        options={list}
        value={selectedOption}
        onChange={SelectValue}
        placeholder="Select Year"
        isMulti
      />
      <div className="py-8 text-center">
        <button
          className="text-pink-500 border border-pink-500 hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase text-xs px-8 py-2 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => {
            addValue();
          }}
        >
          Add
        </button>
      </div>
    </div>
  );
}
