import {
  ALL_CLUBS_LIST,
  ALL_DIRECTION_LIST,
  ALL_INTEREST_LIST,
  USER_CHANNELS_REQUEST,
  USER_CLUBS_REQUEST,
  USER_DETAILS_REQUEST,
  USER_DIRECTIONS_REQUEST,
  USER_EXPERIENCES_REQUEST,
  USER_INTERESTS_REQUEST,
  USER_NOTES_REQUEST,
} from "../constants/userConstants";

export interface Club {
  id: string;
  name: string;
  position: string;
}

export interface ProfileState {
  loading?: boolean;
  error?: string;
  userData: {
    data?: string;
    firstName?: string;
    lastName?: string;
    id?: string;
    email?: string;
  };
  // clubData: Club[];
  noteData: any[];
  clubData: any[];
  interestData: any[];
  directionData: any[];
  experienceData: any[];
  clublists: any[];
  interestLists: any[];
  directionLists: any[];
}

interface Action {
  type: string;
  payload?: string;
}

export const ProfileReducer = (
  state: ProfileState = {
    userData: {},
    noteData: [],
    clubData: [],
    interestData: [],
    directionData: [],
    experienceData: [],
    clublists: [],
    interestLists: [],
    directionLists: [],
  },
  action: Action
) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { userData: action.payload };
    case USER_NOTES_REQUEST:
      return { ...state, noteData: action.payload };
    case USER_CLUBS_REQUEST:
      return { ...state, clubData: action.payload };
    case USER_EXPERIENCES_REQUEST:
      return { ...state, experienceData: action.payload };
    case USER_INTERESTS_REQUEST:
      return { ...state, interestData: action.payload };
    case USER_DIRECTIONS_REQUEST:
      return { ...state, directionData: action.payload };
    case USER_CHANNELS_REQUEST:
      return { channelData: action.payload };

    case ALL_CLUBS_LIST:
      return { ...state, clublists: action.payload };
    case ALL_INTEREST_LIST:
      return { ...state, interestLists: action.payload };
    case ALL_DIRECTION_LIST:
      return { ...state, directionLists: action.payload };
    default:
      return state;
  }
};
