// import React, { useEffect } from "react";
import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { BsPersonPlus, BsChevronLeft } from "react-icons/bs";
// import { Dispatch } from "redux";
import { useLocation, useNavigate } from "react-router-dom";

function Group() {
  const location = useLocation();
  const navigate = useNavigate();
  const [openTab, setOpenTab] = React.useState(0);
  const [follow, setFollowState] = React.useState(0);

  // const dispatch: Dispatch<any> = useDispatch();
  useEffect(() => {
    Object.entries(location).map(([key, value]) => {
      if (key == "state") {
        setOpenTab(value.id);
      }
    });
  }, []);

  const goBack = () => {
    navigate("/profile");
  };

  const setFollow = () => {
    if (follow === 0) {
      setFollowState(1);
    } else {
      setFollowState(0);
    }
  };
  const tab = [
    { id: 1, name: "MyGroups" },
    { id: 2, name: "Inactive Groups" },
    { id: 3, name: "Group Invites" },
  ];
  const TabElement = tab.map((m) => {
    return (
      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center" key={m.name}>
        <a
          className={
            "text-xs font-bold px-2 py-2 rounded block leading-normal " +
            (openTab === m.id
              ? "text-blue-500 border-b-4 border-blue-500"
              : "text-gray-400")
          }
          onClick={(e) => {
            e.preventDefault();
            setOpenTab(m.id);
          }}
          data-toggle="tab"
          href={`#${m.name}`}
          role="tablist"
        >
          {m.name}
        </a>
      </li>
    );
  });
  return (
    <div>
      <div className="bg-gray-100">
        <div className="items-center">
          <div className="pt-10">
            <div className="flex justify-between">
              <button className="text-blue-500 flex" onClick={goBack}>
                <BsChevronLeft className="text-3xl pb-1" />
                <span className="text-lg">Back</span>
              </button>
              <div className="text-2xl">Group Management</div>
              <div className=""></div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <ul
                className="flex mb-0 list-none flex-wrap pt-3 px-3 flex-row border-b border-gray-200"
                role="tablist"
              >
                {TabElement}
              </ul>
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="px-4 py-2 flex-auto">
                  <div className="tab-content tab-space">
                    <div
                      className={openTab === 1 ? "block" : "hidden"}
                      id="MyGroups"
                    >
                      <div className="flex justify-between space-x-4 border-b border-gray-200 py-2">
                        <div className="flex">
                          <img
                            src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
                            className="rounded-full w-12 sm:w-14 h-12 sm:h-14 mr-2 sm:mr-4"
                          />
                          <span className="text-base sm:text-lg m-auto">
                            Azore Trek April'22
                          </span>
                        </div>
                        <div className="flex mt-auto mb-auto">
                          {follow === 1 ? (
                            <button
                              className="bg-blue-500 w-16 sm:w-20 h-10 text-sm text-white rounded-md mr-2"
                              onClick={setFollow}
                            >
                              <span>Following</span>
                            </button>
                          ) : (
                            <button
                              className="bg-yellow-500 w-16 sm:w-20 h-10 text-sm text-white rounded-md mr-2"
                              onClick={setFollow}
                            >
                              <span>Click to Follow</span>
                            </button>
                          )}

                          <button className="bg-blue-500 sm:w-20 h-10 text-sm text-white p-2 rounded-md">
                            Active
                          </button>
                        </div>
                      </div>
                      <div className="flex justify-between space-x-4 border-b border-gray-200 py-2">
                        <div className="flex">
                          <img
                            src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
                            className="rounded-full w-12 sm:w-14 h-12 sm:h-14 mr-2 sm:mr-4"
                          />
                          <span className="text-base sm:text-lg m-auto">
                            Azore Trek April'22
                          </span>
                        </div>
                        <div className="flex mt-auto mb-auto">
                          <button className="bg-blue-500 w-16 sm:w-20 h-10 text-sm text-white rounded-md mr-2">
                            <span>Following</span>
                          </button>
                          <button className="bg-blue-500 sm:w-20 h-10 text-sm text-white p-2 rounded-md">
                            Active
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={openTab === 2 ? "block" : "hidden"}
                      id="Inactive Groups"
                    >
                      <div className="flex justify-between space-x-4 border-b border-gray-200 py-2">
                        <div className="flex">
                          <img
                            src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
                            className="rounded-full w-12 sm:w-14 h-12 sm:h-14 mr-2 sm:mr-4"
                          />
                          <span className="text-base sm:text-lg m-auto">
                            Azore Trek April'22
                          </span>
                        </div>
                        <div className="flex mt-auto mb-auto">
                          <button className="bg-gray-500 h-10 text-sm text-white p-2 rounded-md mr-2">
                            Reactivate
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className={openTab === 3 ? "block" : "hidden"}
                      id="Group Invites"
                    >
                      <div className="flex justify-between space-x-4 border-b border-gray-200 py-2">
                        <div className="flex">
                          <img
                            src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
                            className="rounded-full w-12 sm:w-14 h-12 sm:h-14 mr-2 sm:mr-4"
                          />
                          <span className="text-base sm:text-lg m-auto">
                            Azore Trek April'22
                          </span>
                        </div>
                        <div className="flex mt-auto mb-auto">
                          <button className="bg-blue-500 h-10 text-sm text-white p-2 rounded-md mr-2">
                            Accept Invite
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Group;
