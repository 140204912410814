// import React, { useEffect } from "react";
import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { BsPersonPlus, BsChevronLeft } from "react-icons/bs";
// import { Dispatch } from "redux";
import { useLocation, useNavigate } from "react-router-dom";

function OtherGroup() {
  const location = useLocation();
  const navigate = useNavigate();
  const [openTab, setOpenTab] = React.useState(1);
  const [userId, setUserId] = React.useState(0);
  const [follow, setFollowState] = React.useState(0);

  // const dispatch: Dispatch<any> = useDispatch();
  useEffect(() => {
    Object.entries(location).map(([key, value]) => {
      if (key == "state") {
        setUserId(value);
        console.log("value", value);
      }
    });
  }, []);

  const goBack = () => {
    navigate("/savedlists");
  };

  const setFollow = () => {
    if (follow === 0) {
      setFollowState(1);
    } else {
      setFollowState(0);
    }
  };
  const tab = [{ id: 1, name: "Groups" }];
  return (
    <div>
      <div className="bg-gray-100">
        <div className="items-center">
          <div className="pt-10">
            <div className="bg-gray-100 relative">
              <button
                className="text-blue-500 flex absolute top-11 left-4"
                onClick={goBack}
              >
                <BsChevronLeft className="text-xl pt-1 text-blue-500 " />
                <span className="text-lg">Back</span>
              </button>
              <div className="pt-10 text-center text-2xl">Ahmed Eltom</div>
              <div className="pb-6 text-center text-sm text-gray-400">
                last seen just now
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full">
              <ul
                className="flex mb-0 list-none flex-wrap pt-3 px-3 flex-row border-b border-gray-200"
                role="tablist"
              >
                <li className="-mb-px mr-2 last:mr-0 text-center">
                  <a
                    className={
                      "text-xs font-bold px-2 py-2 rounded block leading-normal text-blue-500 border-blue-500 border-b-4"
                      // (openTab === 1
                      //   ? "text-blue-500 border-b-4 border-blue-500"
                      //   : "text-gray-400")
                    }
                    data-toggle="tab"
                    href={`#Group`}
                    role="tablist"
                  >
                    Group
                  </a>
                </li>
              </ul>
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 rounded">
                <div className="px-4 py-2 flex-auto">
                  <div className="tab-content tab-space">
                    <div
                      // className={openTab === 1 ? "block" : "hidden"}
                      id="Group"
                    >
                      <div className="flex justify-between space-x-4 border-b border-gray-200 py-2">
                        <div className="flex">
                          <img
                            src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
                            className="rounded-full w-12 sm:w-14 h-12 sm:h-14 mr-2 sm:mr-4"
                          />
                          <span className="text-base sm:text-lg m-auto">
                            Azore Trek April'22
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-between space-x-4 border-b border-gray-200 py-2">
                        <div className="flex">
                          <img
                            src="https://play-lh.googleusercontent.com/rMT8Gd9S9kYumvI15f5mozDaVk5bTkR9dSG4EfN7ph_yVHj0a0suzqqrBtPXr68rEgQ"
                            className="rounded-full w-12 sm:w-14 h-12 sm:h-14 mr-2 sm:mr-4"
                          />
                          <span className="text-base sm:text-lg m-auto">
                            Azore Trek April'22
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OtherGroup;
