import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  BsFillTelephoneFill,
  BsCameraVideoFill,
  BsFolder2Open,
  BsChevronLeft,
  BsGear,
} from "react-icons/bs";
import TabsRender from "./ProfileTab";
import { RootState } from "../../store";
import {
  getAllClubs,
  getAllDirection,
  getAllInterest,
  getUserDetail,
} from "../../actions/profileAction";
import { ProfileState } from "../../reducers/profileReducer";
import Menu from "../../components/Menu";

function Profile() {
  const dispatch: Dispatch<any> = useDispatch();
  const [openMessage, setOpenMessage] = React.useState(0);

  const user = useSelector<RootState, ProfileState>(
    (state: RootState) => state.userData
  );

  useEffect(() => {
    dispatch(getUserDetail());
    dispatch(getAllClubs());
    dispatch(getAllDirection());
    dispatch(getAllInterest());
  }, []);

  return (
    <div>
      <div className="bg-gray-100">
        <div className="flex items-center justify-center">
          <div className="mt-10 rounded-lg">
            <div className="flex justify-between">
              <div className="text-blue-500 flex">
                <BsChevronLeft className="text-3xl pb-1" />
                <span className="text-lg">Back</span>
              </div>
              <div className="text-blue-500">
                <BsGear className="text-3xl m-auto" />
                <span className="text-xs">Settings</span>
              </div>
            </div>
            <div className="flex items-center justify-center flex-col">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyuNFyw05KSucqjifL3PhDFrZLQh7QAS-DTw&usqp=CAU"
                className="rounded-full w-32 h-32"
              />
              <h1 className="text-gray-800 font-semibold text-3xl mt-5">
                {user.userData?.firstName} {user.userData?.lastName}
              </h1>
              <h1 className="text-gray-400 text-sm">Edit my profile</h1>
            </div>

            <div className="flex items-center justify-center mt-3 mb-6 flex-col">
              <div className="flex mt-2">
                <div className="p-2 bg-white rounded-lg mr-1 sm:mr-2">
                  <div className="bg-blue-500 rounded-lg p-1 pt-2">
                    <div className="box-border h-10 w-12 sm:w-16 text-center font-medium text-xs text-white">
                      <span>Calendar Icon</span>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-lg mr-1 sm:mr-2">
                  <div className="m-2 rounded-lg p-1">
                    <div className="box-border w-10 sm:w-16 text-center font-medium text-xs text-blue-500">
                      <BsFillTelephoneFill className="text-blue-500 text-2xl m-auto pb-1" />
                      <span>Calls</span>
                    </div>
                  </div>
                </div>
                <div className="bg-white rounded-lg mr-1 sm:mr-2">
                  <div className="m-2 rounded-lg p-1">
                    <div className="box-border w-10 sm:w-16 text-center font-medium text-xs text-blue-500">
                      <BsCameraVideoFill className="text-blue-500 text-2xl m-auto pb-1" />
                      <span>Video</span>
                    </div>
                  </div>
                </div>
                <div className="p-2 bg-white rounded-lg mr-1 sm:mr-2">
                  <div className="bg-blue-500 rounded-lg p-1 pt-2">
                    <div className="box-border h-10 w-12 sm:w-16 text-center font-medium text-xs text-white">
                      <span>Standard Contacts</span>
                    </div>
                  </div>
                </div>
                <Menu />
              </div>
            </div>

            <div className="flex justify-center mt-3 mb-6 flex-col bg-white p-2">
              <div className="border-b border-gray-200 flex justify-around">
                <div className="grid">
                  <span className="text-xs font-bold">mobile</span>
                  <span className="text-lg text-blue-500">+44 7719 374670</span>
                </div>
                <div className="grid">
                  <span className="text-xs font-bold">school program</span>
                  <span className="text-lg text-center">MBA 2023</span>
                </div>
              </div>
              <div className="pt-2 flex justify-around">
                <div className="grid">
                  <span className="text-xs font-bold">Email(s)</span>
                  <span className="text-sm">name.MBA2023@school.edu</span>
                  <span className="text-sm">Personal.email@google.com</span>
                </div>
                <div className="grid">
                  <a
                    className={
                      openMessage === 1
                        ? "text-blue-500 border-b-4 border-blue-500"
                        : ""
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (openMessage === 1) {
                        setOpenMessage(0);
                      } else {
                        setOpenMessage(1);
                      }
                    }}
                  >
                    <span className="text-xs font-bold ">Saved Messages</span>
                    <BsFolder2Open className="text-4xl m-auto pb-1" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <TabsRender openMessage={openMessage} />
      </div>
    </div>
  );
}
export default Profile;
