export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";

export const USER_NOTES_REQUEST = "USER_NOTES_REQUEST";
export const USER_NOTES_SAVE = "USER_NOTES_SAVE";
export const USER_NOTES_DELETE = "USER_NOTES_DELETE";

export const USER_CLUBS_REQUEST = "USER_CLUBS_REQUEST";
export const USER_CLUBS_SAVE = "USER_CLUBS_SAVE";
export const USER_CLUBS_DELETE = "USER_CLUBS_DELETE";

export const USER_EXPERIENCES_REQUEST = "USER_EXPERIENCES_REQUEST";
export const USER_EXPERIENCES_SAVE = "USER_EXPERIENCES_SAVE";

export const USER_INTERESTS_REQUEST = "USER_INTERESTS_REQUEST";
export const USER_INTEREST_SAVE = "USER_INTEREST_SAVE";
export const USER_INTEREST_DELETE = "USER_INTEREST_DELETE";

export const USER_DIRECTIONS_REQUEST = "USER_DIRECTIONS_REQUEST";
export const USER_DIRECTION_SAVE = "USER_DIRECTION_SAVE";
export const USER_DIRECTION_DELETE = "USER_DIRECTION_DELETE";

export const USER_CHANNELS_REQUEST = "USER_CHANNELS_REQUEST";

export const ALL_CLUBS_LIST = "ALL_CLUBS_LIST";
export const ALL_INTEREST_LIST = "ALL_INTEREST_LIST";
export const ALL_DIRECTION_LIST = "ALL_DIRECTION_LIST";
